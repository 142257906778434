// eslint-disable-next-line no-unused-vars
import axios from 'axios'
import { authHeader } from "../../helpers/authservice/auth-header";
// eslint-disable-next-line no-unused-vars
const base = process.env.VUE_APP_API_BASEURL


export const state = {
    requests: [],
    approval_requests:[],
    approvalUsers: [],
    types: [],
  };
  
  export const actions = {
    fetchRequestsAPI({ commit }, id) {
      return axios
          .get(base + `api/requests/requests/${id}/`, authHeader)
          .then((res) => {
            commit("setItems", { requests: res.data.requests });
            commit("setItems", { approval_requests: res.data.approval_requests });
  
          })
          .catch((error) => {
            console.log("Error:", error);
  
          });
    },
    fetchApprovalUsersAPI({ commit }, id) {
      return axios
        .get(base + `api/requests/approveusers/${id}/`, authHeader)
        .then((res) => {
          commit("setItems", { approvalUsers: res.data.users });
        })
        .catch((error) => {
          console.log("Error:", error);
        });
    },
    fetchTypes({ commit }) {
  
      return axios
        .get(base + "api/requests/types/", authHeader)
        .then((res) => {
          console.log(res);
          commit("setItems", { types: res.data });
        })
        .catch((error) => {
          console.log("Error:", error);
        });
    },
    approveReject({ commit }, payload) {
      return axios
        .post(base + "api/requests/approvereject/", payload, authHeader)
        .then((res) => {
          console.log(res);
          console.log(commit);
        })
        .catch((error) => {
          console.log("Error:", error);
        });
    },
    deleteRequestAPI({commit}, payload){
      console.log(commit);
      return axios
      .post(base + "api/requests/deleterequest/", payload, authHeader)
    },
    editRequestAPI({ commit }, payload) {
      console.log(commit);
      return axios
        .post(base + "api/requests/saverequest/", payload, authHeader)
    },
    saveTypesAPI({commit}, payload){
      console.log(commit);
      return axios
        .post(base + "api/requests/savetype/", payload, authHeader)
    },
    findApprovalUserAPI({commit},payload){
      console.log(commit);
      return axios
        .post(base + "api/requests/findemailusers/", payload, authHeader)
    },
    saveApprovalUserAPI({commit},payload){
      console.log(commit);
      return axios
        .post(base + "api/requests/saveapprovalchain/", payload, authHeader)
    },
    deleteApprovalUserAPI({commit},payload){
      console.log(commit);
      return axios
        .post(base + "api/requests/deleteapprovalrequest/", payload, authHeader)
    },
    saveCommentAPI({commit},payload){
      console.log(commit);
      return axios
        .post(base + "api/requests/savecomment/", payload, authHeader)
    },
  };
  
  export const mutations = {
    setItems(state, payload) {
      const key = Object.keys(payload).toString();
      state[key] = payload[key];
    },
  };
  
