export const state = {
    is_kr_scrubber_expanded: false,
    is_kpi_scrubber_expanded: false,
}

export const actions = {
    setScrubberState({commit}, payload){
        commit('setScrubberState', payload)
        //* payload : { type, state }
    },
    
}

export const mutations = {
    setScrubberState(state, payload){
        switch(payload.type){
            case 'key_result':{
                state.is_kr_scrubber_expanded = payload.state;
                break;
            }
            case 'kpi':{
                state.is_kpi_scrubber_expanded = payload.state;
                break;
            }
        }
    }
}
