/**
 * @author: Dan Van <daniel@forresteldertech.co.za>
 * @copyright: Diversitude 2024
 */

//eslint-disable-next-line
import { getYear, parseISO, getQuarter, addMonths } from 'date-fns';
import _, { uniqueId } from 'lodash';
import Vue from 'vue'; // Import Vue

export const state = {
  chat_loading: false,
  messages_loading: false,
  is_visible_emoji_picker: false,
  is_visible_context_menu: false,
  loading: false, // will be true if either of the loadings are true
  selected_message_context: null, // used for handling context menus & emojiPicker @see src/router/views/menu-pages/chat.vue
  current_chat_room: null, // the ID of the current chat room || null if no room selected
  previous_chat_room: null, // the ID of the current chat room || null if no room selected
  chat_rooms: [], // chat rooms the user is a part of
  users_last_seen: [], // last seen list of users
  chat_notifications: [], // potential notifcation array
  chat_room_messages: [], // messages of the current chat room
  chat_room_users: [], // online users of the current chat room
  chat_room_particpants: [], // all users of the current chat room
  chat_room_message_cache: [], //@todo cache messages when switching context to save HTTP requests

  is_chat_visible: false, 
};
//eslint-disable-next-line
const CHAT_ROOM_TYPES = {
  USER: 'user',
  GROUP: 'team',
  ORG: 'level',
  PRESENCE: 'client-channel',
};

export const actions = {
  toggleChatVisibility({ commit }, payload) {
    commit('setChatVisibility', payload);
  },
  toggleContextMenuVisible({ commit }, payload = null) {
    commit('setSelectedMessageContext', payload);
    return commit('toggleContextMenuVisible');
  },
  toggleEmojiPickerVisible({ commit }, payload = null) {
    commit('setSelectedMessageContext', payload);
    return commit('toggleEmojiPickerVisible');
  },
  /**
   * ===============================
   *          STATE ACTIONS
   * ===============================
   */

  /**
   * Registers a new chat room for the currently logged in user
   * This will automatically join a chat for the user if a chat room already exists between the two users
   * @return Promise
   */
  async newChat({ commit, dispatch, state }, payload) {
    const { userId, type } = payload;
    commit('toggleChatLoading');
    return new Promise((resolve, reject) => {
      window.axios
        .post('/api/chat/join_room', {
          room_type: type,
          linked_id: userId,
        })
        .then((response) => {
          if (response.status !== 200) {
            return reject();
          }
          // Update the list of registered chat rooms
          dispatch('updateUserChatRooms');
          // Set the current chat room
          commit('setPreviousChatRoom', state.current_chat_room);
          commit('setCurrentChatRoom', response.data?.room_id);
          commit('setCurrentChatParticipants', response.data?.participants);
          commit('toggleChatLoading');

          // Set the messages for the chat room
          return resolve(response);
          // resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * Sends a message to the current chat room
   * @param {*} param0
   * @param {String} message The HTML Content from the Quill setLastEditorID
   */
  async sendNewMessage({ commit, dispatch, state }, message) {
    let response = await dispatch('sendMessage', {
      room_id: state.current_chat_room,
      message: message,
    }).then((response) => {
      const { message } = response.data;
      if (response?.data?.success ?? false) {
        commit('pushChatRoomMessage', message);
        return response.data;
      } else {
        console.error('There was an error sending the message');
        console.debug({ response });
      }
    });
    return response;
  },
  /**
   * Reads All the Room Messages and Mutates the state
   * @param {*} param0
   * @param {*} chatRoom this.current_chat_room
   */
  readRoom({ dispatch }, chatRoom) {
    return dispatch('markRoomRead', chatRoom.id);
  },
  async handleContextMenuDelete({ commit, dispatch }, message) {
    return await dispatch('deleteMessage', message.id).then((response) => {
      commit('removeMessage', message.id);
      return response;
    });
  },

  /**
   * ===============================
   *          ECHO HANDLERS
   * ===============================
   */
  onChatRoomRead({ commit, rootState }, chatRoomId) {
    let currentUser = rootState.admin.core;
    return commit('readRoomMessages', { chatRoomId, currentUser });
  },
  // eslint-disable-next-line
  onChatMessageRead({ commit, rootState }, payload) {
    payload.currentUser = rootState.admin.core;
    return commit('readMessages', payload);
  },
  /**
   * fetch and read the new message received
   * @param {*} param0
   * @param {*} payload onChatMessageSent Payload
   * @returns {Object}
   */
  async onChatMessageSent({ dispatch }, payload) {
    const { messageId } = payload;
    return await dispatch('getChatRoomMessage', messageId).then(async (response) => {
      // if an error return the promise
      if (response.status !== 200) {
        console.error('onMessagesent.errr', response);
        return Promise.reject(response);
      }
      const readResponse = await dispatch('readMessage', response.data.id).then((response) => {
        if (response.status !== 200) {
          console.error('onMessagesent.onRead.errr', response);
          return Promise.reject(response);
        }
        return response;
      });
      return { success: true, readResponse, response };
    });
  },
  async onChatMessageReaction({ commit }, payload) {
    console.debug('store.onChatMessageReaction', payload);
    commit('onReactToMessage', payload);
  },
  async onHere({ commit }, payload) {
    if (_.isArray(payload)) {
      commit('setCurrentChatRoomUsers', payload);
    } else {
      commit('setCurrentChatRoomUsers', [payload]);
    }
  },

  onLeaving({ commit }, payload) {
    commit('removeCurrentChatRoomUser', payload);
  },

  /**
   * ===============================
   *          STATE UPDATERS
   * ===============================
   */
  async updateUserChatRooms({ commit, dispatch }) {
    let response = await dispatch('getUserChatRooms');
    commit('setUserChatRooms', response?.data ?? []);
    return response;
  },
  /**
   * Fetch and update chat room messages for the current chat room
   */
  async updateCurrentChatRoomMessages({ commit, dispatch, state }) {
    let response = await dispatch('getChatRoomMessages', state.current_chat_room);
    commit('setChatRoomMessages', response?.data?.messages ?? []);
    return response;
  },
  /**
   * Fetch and update chat room messages for a certain chat room
   */
  async updateChatRoomMessages({ commit, dispatch }, id) {
    let response = await dispatch('getChatRoomMessages', id);
    commit('setChatRoomMessages', response?.data?.messages ?? []);
  },

  async getChatRoomMessage({ commit, dispatch }, message_id) {
    let response = await dispatch('fetchMessage', message_id);
    commit('pushChatRoomMessage', response?.data ?? {});
    return response;
  },
  /**
   * ===============================
   *          HTTP GETTERS
   * ===============================
   */

  /**
   * Returns the last seen time for all client users
   * @returns Promise
   */
  async getUsersLastSeen() {
    return await window.axios.get('api/chat/users_last_seen');
  },

  /**
   * Return the list of user chat rooms
   * @returns {Promise}
   */
  async getUserChatRooms() {
    return await window.axios.get('api/chat/user_chatrooms');
  },

  /**
   * Fetch ALL the chat messages for a specific room from the API
   *
   * @param {String} id
   * @param {Number} limit
   * @returns {Promise}
   */
  // eslint-disable-next-line
  async getChatRoomMessages({}, id, limit = null) {
    return await window.axios.get('api/chat/messages', {
      params: { room_id: id },
    });
  },

  /**
   * ===============================
   *          HTTP POSTS
   * ===============================
   */
  // eslint-disable-next-line
  async sendMessage({ commit, dispatch }, { room_id, message }) {
    return await window.axios.post('api/chat/send_message', {
      room_id,
      message,
    });
  },
  // eslint-disable-next-line
  async reactToMessage({ commit, dispatch }, { message_id, emoji }) {
    let selected_emoji = emoji.aliases[0];
    return await window.axios.post('api/chat/react_messages', {
      messages: [
        {
          message_id,
          emoji: selected_emoji,
        },
      ],
    });
  },
  // eslint-disable-next-line
  async readMessage({}, message_id) {
    return await window.axios.post('api/chat/read_messages', {
      messages: [
        {
          message_id,
        },
      ],
    });
  },

  // eslint-disable-next-line
  async deleteMessage({}, message_id) {
    return await window.axios.post('api/chat/delete_message', { message_id });
  },

  // eslint-disable-next-line
  async markRoomRead({}, room_id) {
    return await window.axios.post('api/chat/mark_room_read', { room_id });
  },
  /**
   * Fetch a specific message
   * @param {} param0
   * @param {Number} message_id
   * @returns {Promise}
   */
  // eslint-disable-next-line
  async fetchMessage({}, message_id) {
    return await window.axios.get('api/chat/message', {
      params: {
        message_id,
      },
    });
  },
};

export const mutations = {
  setUserChatRooms(state, payload) {
    state.chat_rooms = payload;
  },
  setUsersLastSeen(state, payload) {
    state.users_last_seen = payload;
  },
  setCurrentChatRoom(state, payload) {
    state.current_chat_room = payload;
  },
  setCurrentChatParticipants(state, payload) {
    state.chat_room_particpants = payload;
  },
  setCurrentChatRoomUsers(state, payload) {
    state.chat_room_users = payload;
  },

  removeCurrentChatRoomUser(state, payload) {
    const { id } = payload;
    const userIndex = state.chat_room_users.findIndex((user) => user.id === id);
    // If the message is found, remove it from the array
    if (userIndex !== -1) {
      state.chat_room_users = state.chat_room_users.filter((user, index) => index !== userIndex);
    } else {
      console.error(`Chat Room User with id ${payload.id} not found.`);
    }
  },
  setPreviousChatRoom(state, payload) {
    state.previous_chat_room = payload;
  },
  setChatRoomMessages(state, payload) {
    state.chat_room_messages = payload;
  },
  setSelectedMessageContext(state, payload) {
    state.selected_message_context = payload;
  },
  pushChatRoomMessage(state, payload) {
    state.chat_room_messages.push(payload);
  },
  removeMessage(state, message_id) {
    // Find the index of the message with the given message_id
    const index = state.chat_room_messages.findIndex((message) => message.id === message_id);

    // If the message is found, remove it from the array
    if (index !== -1) {
      Vue.set(state.chat_room_messages[index], 'deleted_at', new Date().toISOString());
    } else {
      console.error(`Message with id ${message_id} not found.`);
    }
  },
  readMessages(state, payload) {
    const { messageIds, userId } = payload;
    // Find the message by ID in the chat room's messages
    const messagesToUpdate = state.chat_room_messages.filter((message) => messageIds.includes(message.id));
    if (!messagesToUpdate.length) {
      console.error(`No messages found with IDs ${messageIds.join(', ')} in chat room ${state.current_chat_room}.`);
      return;
    }
    // Push read receipt to each message's read_receipts property
    const readReceipt = {
      created_at: new Date().toISOString(), // Use current timestamp
      id: uniqueId(), // Generate a unique ID for the read receipt
      message_id: null, // Will be updated for each message
      user_id: userId, // Will be updated for each message
      read_at: new Date().toISOString(), // Use current timestamp
      updated_at: new Date().toISOString(), // Use current timestamp
    };
    messagesToUpdate.forEach((message) => {
      if (_.isUndefined(message.read_receipts)) {
        Vue.set(message, 'read_receipts', []);
      }
      message.read_receipts.push({ ...readReceipt, message_id: message.id });
    });
  },
  readRoomMessages(state, payload) {
    let { currentUser } = payload;
    // Only update unread messages not from the currently logged in user
    const messagesToUpdate = state.chat_room_messages.filter(
      (message) => message.read_receipts.length <= 0 && message.user_id !== currentUser.id
    );

    if (!messagesToUpdate.length) {
      console.error(`No messages found to update in chat room ${payload.chatRoomId}.`);
      return;
    }
    // Push read receipt to each message's read_receipts property
    const readReceipt = {
      created_at: new Date().toISOString(), // Use current timestamp
      id: _.uniqueId(), // Generate a unique ID for the read receipt
      message_id: null, // Will be updated for each message
      read_at: new Date().toISOString(), // Use current timestamp
      updated_at: new Date().toISOString(), // Use current timestamp
    };
    messagesToUpdate.forEach((message) => {
      console.log({ message });
      if (_.isUndefined(message.read_receipts)) {
        Vue.set(message, 'read_receipts', []);
      }
      message.read_receipts.push({ ...readReceipt, message_id: message.id });
    });
  },
  onReactToMessage(state, payload) {
    const { reactions, userId } = payload;

    // Find the message by ID in the chat room's messages
    const messageToUpdate = state.chat_room_messages.find((message) => message.id === reactions[0].message_id);

    if (!messageToUpdate) {
      console.error(
        `Message with ID ${payload.reactions[0].message_id} not found in chat room ${state.current_chat_room}.`
      );
      return;
    }
    if (_.isUndefined(messageToUpdate.reactions)) {
      Vue.set(messageToUpdate, 'reactions', []);
    }
    // Add reactions to the message
    // If reactions array already exists, append new reactions
    reactions.forEach((reaction) => {
      reaction.user_id = userId;
      reaction.created_at = new Date().toISOString();
      reaction.updated_at = new Date().toISOString();
      // Check if we have a reaction of the same type from the same user
      const existingReactionIndex = messageToUpdate.reactions.findIndex((r) => r.user_id === userId);
      if (existingReactionIndex !== -1) {
        // If a reaction with the same type from the same user exists, replace its emoji
        messageToUpdate.reactions[existingReactionIndex].emoji = reaction.emoji;
      } else {
        // Otherwise, append the new reaction
        messageToUpdate.reactions.push(reaction);
      }
      // Check if we have a reaction of the same type from the same user
      // if (!messageToUpdate.reactions.some((r) => r.user_id === userId && r.emoji === reaction.emoji)) {
      //   messageToUpdate.reactions.push(reaction);
      // }
    });
  },
  /**
   * @param {*} state
   * @param {*} payload Full Message From getMessage Endpoint
   * @see https://rapidapi.com/studio/api_0faaf737-1f90-4749-a3ae-00ea3b739d24/client/5d4fc08e-a005-42eb-8b73-90dd60924e31
   */
  updateChatRoomMessage(state, payload) {
    const messageIdToUpdate = payload.id;
    const updatedMessage = payload;
    const index = state.chat_room_messages.findIndex((message) => message.id === messageIdToUpdate);
    if (index !== -1) {
      // If the message is found, update it
      state.chat_room_messages.splice(index, 1, updatedMessage);
    }
  },
  toggleEmojiPickerVisible(state) {
    state.is_visible_emoji_picker = !state.is_visible_emoji_picker;
  },

  toggleChatLoading(state) {
    state.chat_loading = !state.chat_loading;
  },
  setEmojiPickerVisible(state, payload) {
    state.is_visible_emoji_picker = payload;
  },
  toggleContextMenuVisible(state) {
    state.is_visible_context_menu = !state.is_visible_context_menu;
  },
  setContextMenuVisible(state, payload) {
    state.is_visible_context_menu = payload;
  },
  setChatVisibility(state, payload) {
    state.is_chat_visible = payload;
  },
};

export const getters = {
  getCurrentChatRoom: (state) => {
    return state.chat_rooms.find((room) => room.id === state.current_chat_room);
  },
  getChatRoomById: (state) => (chat_room_id) => {
    return state.chat_rooms.find((room) => room.id === chat_room_id);
  },
  /**
   * Get an online user from the store
   * @param {*} state
   * @returns {Object}
   */
  getChatRoomUser: (state) => (user_id) => {
    return state.chat_room_users.find((user) => user.id === user_id);
  },

  getChatRoomParticipant: (state) => (user_id) => {
    return state.chat_room_particpants.find((user) => user.id === user_id);
  },
  getAllUnreadMessages: (state) => {
    return state.chat_room_messages.filter((msg) => msg.read_receipts.length <= 0);
  },
  //eslint-disable-next-line
  findChatMessages: (state) => (chat) => {
    return state.chat_room_messages;
  },
};
