import Vue from 'vue'
export const state = {
    guideline_array: [],
    selected_guideline_id: null,
    is_loading_guidelines: false,

}

export const getters = {
    getSelectedGuideline(state){
        if(state.selected_guideline_id == null){
            return null;
        }
        else{
            let item = state.guideline_array.find( item => {
                return item.type == state.selected_guideline_id;
            });
            return item;
        }
    }
}


export const actions = {
    //eslint-disable-next-line
    saveGuidelineBody({commit, dispatch}, params){
        commit('setLoadingGuidelines', true);
        return new Promise( (resolve) => {
            window.axios.post('api/admin/save_guideline', params)
            .then(()=>{
                dispatch('setSelectedGuidelineID', {
                    type: params.type,
                    force_reload: false,
                })
            })
            resolve();
        })
    },
    setSelectedGuidelineID({commit}, payload){
        const type = payload.type;

        if(type == null){
            //* stop loading if type is null (unset)
            commit('setSelectedGuidelineID', type);
            return new Promise( resolve => { resolve() });
        }

       //const force_reload = payload.force_reload != undefined ? payload.force_reload : false;
        commit('setLoadingGuidelines', true);
        commit('setSelectedGuidelineID', type);
        return new Promise( (resolve) => {
            commit('setLoadingGuidelines', false);
            resolve();
        });
    },
}

export const mutations = {
    setSelectedGuidelineID(state, id){
        state.selected_guideline_id = id;
    },
    setLoadingGuidelines(state, value){
        state.is_loading_guidelines = value;
    },
    updateGuidelineArray(state, params){
        if(params.index == -1){
            state.guideline_array.push(params.guideline_data);
        }
        Vue.set(state.guideline_array, params.index, params.guideline_data);
    }
}
