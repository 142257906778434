
import axios from 'axios'
import store from '@/state/store'
import { format } from 'date-fns';

export const state = {
    selected_team_id: -1,
    selected_org_level: -1,
    teams:[],
    last_used_level_id: -1,
    linking_objective_plans:[],
    selected_process_map_item_id: -1,
    selected_process_map_process_steps: -1,
    selected_process_map_process: -1,
    process_map_mode: 0,
    targets: [],
    processMapData: [],
    kpis: [],
    searchItems: [],
    searchKPI: [],
    profilePageIndex: -1,
    selectedJobId: -1,
    hiddenOrgChart: false
};

export const mutations = {
    setSelectedProcessMapItemId(state, id) {
        state.selected_process_map_item_id = id;
    },
    setSelectedProcessMapProcessSteps(state, id) {
        state.selected_process_map_process_steps = id;
    },
    setSelectedProcessMapProcess(state, id) {
        state.selected_process_map_process = id;
    },
    setProcessMapView(state,payload) {
        state.process_map_mode = payload
    },
    setSelectedTeamId(state, id){
        state.selected_team_id = id;
    },
    setSelectedOrgLevelID(state, id){
        state.selected_org_level = id;
    },
    setLastUsedLevelID(state, payload){
        state.last_used_level_id = payload;
    },
    setTeams(state, payload){
        state.teams = payload;
        state.linking_objective_plans = state.teams.linkable_plans.map( item => {
            //console.log(item.parent_id);
            return {
                id: item.id,
                value: item.name,
            }
        })
    },
    setTargets(state, payload) {
        state.targets = payload
    },
    setKPIs(state, payload) {
        state.kpis = payload
    },
    setSearchItem(state, payload) {
        state.searchItems = payload
    },
    setSearchKPI(state, payload) {
        state.searchKPI = payload
    },
    setProcessMapData(state, payload) {
        state.processMapData = payload
    },
    setHiddenOrgChart(state, payload) {
        state.hiddenOrgChart = payload
    },
    setProfilePageIndex(state, payload) {
        state.profilePageIndex = payload
    },
    setSelectedJob(state, id) {
        state.selectedJobId = id;
    },
};

export const actions = {
    hideOrgChart({commit}, params) {
        commit('setHiddenOrgChart', params)
    },
    setSelectedJobId({commit}, id){
        commit('setSelectedJob', id)
    },
    setSelectedTeam({commit}, id){
        commit('setSelectedTeamId', id)
    },
    setProcessMapID({commit}, id) {
        commit('setSelectedProcessMapItemId', id)
    },
    setProcessMapProcessSteps({commit}, id) {
        commit('setSelectedProcessMapProcessSteps', id)
    },
    setProcessMapProcess({commit}, id) {
        commit('setSelectedProcessMapProcess', id)
    },
    setProcessMapViewMode({commit}, payload) {
        console.log(payload)
        console.log(commit)
        commit('setProcessMapView', payload)
    },
    setSelectedOrgLevel({commit}, id){
        commit('setSelectedOrgLevelID', id)
    },
    changeProfileTabIndex({commit}, id) {
        commit('setProfilePageIndex', id)
    },
    manuallySetLastUsedLevelID({commit}, id) {
        commit('setLastUsedLevelID', id)
    },
    //eslint-disable-next-line
    saveLevelTeams({}, params){
        return new Promise((resolve, reject) => {
            window.axios.post('api/org/saveteams', params)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Error', error);
                reject();
            })
        })
    },

    //eslint-disable-next-line
    saveServiceProviders({}, params){
        return new Promise((resolve, reject) => {
            window.axios.post('api/org/saveserviceprovider', params)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Error', error);
                reject();
            })
        })
    },

    //eslint-disable-next-line
    saveClientServiceProviders({}, params){
        return new Promise((resolve, reject) => {
            window.axios.post('api/org/saveclientserviceproviders', params)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Error', error);
                reject();
            })
        })
    },

    //eslint-disable-next-line
    deleteClientServiceProviders({}, params){
        return new Promise((resolve, reject) => {
            window.axios.post('api/org/deleteclientserviceprovider', params)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Error', error);
                reject();
            })
        })
    },

     //eslint-disable-next-line
     saveCommunicationTools({}, params){
        return new Promise((resolve, reject) => {
            axios.post('api/org/savecomtools', params)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Error', error);
                reject();
            })
        })
    },

     //eslint-disable-next-line
     saveClientTools({}, params){
        return new Promise((resolve, reject) => {
            axios.post('api/org/saveclienttools', params)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Error', error);
                reject();
            })
        })
    },

    //eslint-disable-next-line
    deleteClientTools({}, params){
        return new Promise((resolve, reject) => {
            window.axios.post('api/org/deleteclienttool', params)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Error', error);
                reject();
            })
        })
    },

    //eslint-disable-next-line
    saveValues({}, params){
        return new Promise((resolve, reject) => {
            window.axios.post('api/org/savevalues', params)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Error', error);
                reject();
            })
        })
    },

     //eslint-disable-next-line
     saveProductsAndServices({}, params){
        return new Promise((resolve, reject) => {
            window.axios.post('api/org/saveoutputs', params)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Error', error);
                reject();
            })
        })
    },

    //eslint-disable-next-line
    saveLevelAssets({}, params){
        return new Promise((resolve, reject) => {
            window.axios.post('api/org/saveassets', params)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Error', error);
                reject();
            })
        })
    },

   //eslint-disable-next-line
    deleteServiceProviders({}, params){
        return new Promise((resolve, reject) => {
            window.axios.post('api/org/deleteserviceprovider', params)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Error', error);
                reject();
            })
        })
    },

    //eslint-disable-next-line
    deleteCommunicationTools({}, params){
        return new Promise((resolve, reject) => {
            window.axios.post('api/org/deletecomtool', params)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Error', error);
                reject();
            })
        })
    },

    //eslint-disable-next-line
    deleteProductsAndServices({}, params){
        console.log(params)
        return new Promise((resolve, reject) => {
            window.axios.post('api/org/deleteoutput', params)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Error', error);
                reject();
            })
        })
    },

     //eslint-disable-next-line
     deleteValues({}, params){
        return new Promise((resolve, reject) => {
            window.axios.post('api/org/deletevalue', params)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Error', error);
                reject();
            })
        })
    },

    //eslint-disable-next-line
    deleteLevelAssets({}, params){
        return new Promise((resolve, reject) => {
            window.axios.post('api/org/deleteasset', params)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Error', error);
                reject();
            })
        })
    },
    
    //eslint-disable-next-line
    deleteTeams({}, params) {
        return new Promise((resolve, reject) => {
            window.window.axios.post('api/org/deleteteam', params)
            .then(()=>{
                resolve();
            })
            .catch(() => {
                reject();
            })
        })
    },
    //eslint-disable-next-line
    saveProcesses({}, params){
        return new Promise((resolve, reject) => {
            window.axios.post('api/process/save_processes', params)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Error', error);
                reject();
            })
        })
    },

     //eslint-disable-next-line
     saveProcessSteps({}, params){
        return new Promise((resolve, reject) => {
            window.axios.post('api/process/save_steps', params)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Error', error);
                reject();
            })
        })
    },

     //eslint-disable-next-line
     saveProcedures({}, params){
        return new Promise((resolve, reject) => {
            window.axios.post('api/process/save_procedures', params)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Error', error);
                reject();
            })
        })
    },

    //eslint-disable-next-line
     createGraph({}, params){
        return new Promise((resolve, reject) => {
            window.axios.post('api/process/create_graph', params)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Error', error);
                reject();
            })
        })
    },

    //eslint-disable-next-line
    deleteProcesses({}, params) {
        return new Promise((resolve, reject) => {
            window.axios.post('api/process/delete_process', params)
            .then(()=>{
                resolve();
            })
            .catch(() => {
                reject();
            })
        })
    },

     //eslint-disable-next-line
     deleteProcessSteps({}, params) {
        return new Promise((resolve, reject) => {
            window.axios.post('api/process/delete_step', params)
            .then(()=>{
                resolve();
            })
            .catch(() => {
                reject();
            })
        })
    },

     //eslint-disable-next-line
     deleteProcedures({}, params) {
        return new Promise((resolve, reject) => {
            window.axios.post('api/process/delete_procedure', params)
            .then(()=>{
                resolve();
            })
            .catch(() => {
                reject();
            })
        })
    },

     //eslint-disable-next-line
     saveProcess({}, params){
        return new Promise((resolve, reject) => {
            window.axios.post('api/org/saveteams', params)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Error', error);
                reject();
            })
        })
    },
    //eslint-disable-next-line
    loadProcessMapData({commit}, param){
        let params = {
            user_id: param.user_id, 
            level_output_id: param.level_output_id
        }
        return new Promise((resolve, reject)=>{
            window.axios.get('api/process/data', { params: params })
            .then((response)=>{
                commit('setProcessMapData', response.data);
                resolve();
            })
            .catch(()=>{
                reject();
            })
        })
    },
    //eslint-disable-next-line
    loadPerformanceTargets({commit}, level_id) {
        let params = {
            level_id: level_id,
            graph_data: 1
        }
        //let dates = store.getters['activity/getQuarterlyDateRange'];
        let dates = store.getters['performance/objectives/viewableRangeDate'];
        params['start_date'] = format(dates.range_start, 'yyyy-MM-dd');
        //params['end_date'] = format(subDays(dates.end, 1), 'yyyy-MM-dd');
        params['end_date'] = format(dates.range_end, 'yyyy-MM-dd');


        return new Promise((resolve, reject)=>{
            window.axios.get('api/performance/data', { params: params })
            .then((response)=>{
                console.log("###############")
                console.log(response);
                commit('setTargets', response.data.targets);
                commit('setKPIs', response.data.kpis);
                resolve();
            })
            .catch(()=>{
                reject();
            })
        })    
    },

    searchItem({commit}, data) {
        let params = {
            type: data.type, 
            search_name: data.search_name
        }
        console.log(params)
        return new Promise((resolve, reject)=>{
            window.axios.get('api/performance/find_item', { params: params })
            .then((response)=>{
                console.log(response);
                commit('setSearchItem', response.data);
                resolve();
            })
            .catch(()=>{
                reject();
            })
        })    
    },

    searchKPIGraphs({commit}, search_name) {
        let params = {
            type: 'kpi', 
            search_name: search_name
        }
        return new Promise((resolve, reject)=>{
            window.axios.get('api/performance/find_item', { params: params })
            .then((response)=>{
                console.log(response);
                commit('setSearchKPI', response.data);
                resolve();
            })
            .catch(()=>{
                reject();
            })
        })    
    },

    loadTeams({commit, rootState}, level_id){
        let milestone_id = rootState.activity.pinned_milestone_id;
        let params = {
            level_id: level_id
        }
        if(milestone_id != -1){
            params['milestone_id'] = milestone_id;
        }
        return new Promise((resolve, reject)=>{
            window.axios.get('api/org/leveldata', { params: params })
            .then((response)=>{
                console.log(response.data);
                commit('setTeams', response.data);
                commit('setLastUsedLevelID', level_id);
                resolve();
            })
            .catch(()=>{
                reject();
            })
        })
    },
    //eslint-disable-next-line
    deleteTeams({}, params) {
        return new Promise((resolve, reject) => {
            window.axios.post('api/org/deleteteam', params)
            .then(()=>{
                resolve();
            })
            .catch(() => {
                reject();
            })
        })
    },

    //eslint-disable-next-line
    saveFrictions({}, params){
        return new Promise((resolve, reject) => {
            window.axios.post('api/org/savefrictions', params)
            .then(()=>{
                resolve();
            })
            .catch(() => {
                reject();
            })
        })
    },
    //eslint-disable-next-line
    deleteFrictions({}, params){
        return new Promise((resolve, reject) => {
            window.axios.post('api/org/deletefriction', params)
            .then(()=>{
                resolve();
            })
            .catch(() => {
                reject();
            })
        })
    },

    //eslint-disable-next-line
    saveSystems({}, params){
        return new Promise((resolve, reject) => {
            window.axios.post('api/org/savesystems', params)
            .then(()=>{
                resolve();
            })
            .catch(() => {
                reject();
            })
        })
    },
    //eslint-disable-next-line
    deleteSystems({}, params){
        return new Promise((resolve, reject) => {
            window.axios.post('api/org/deletesystem', params)
            .then(()=>{
                resolve();
            })
            .catch(() => {
                reject();
            })
        })
    }
};