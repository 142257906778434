/**
 * @Author: Calvin Green <calvin>
 * @Date:   2021-12-04T10:46:04+02:00
 * @Email:  calvin@diversitude.com
 * @Last modified by:   calvin
 * @Last modified time: 2021-12-04T12:32:10+02:00
 * @Copyright: Diversitude 2021
 */




import _ from 'lodash';
import store from '@/state/store'

//eslint-disable-next-line
import { getYear, parseISO, getQuarter, addMonths} from 'date-fns';
//eslint-disable-next-line
import { parse, format, isEqual, isAfter, isBefore, startOfQuarter, endOfQuarter} from 'date-fns'

export const state = {
    activity_data: null,                //* holds milestones/objectives for the selected level
    selected_milestone: null,           //* object of selected milestone
    pinned_milestone_id: -1,

    selected_objective: null,           //* object of selected objective
    selected_objectives: [],
    multiple_selected_objective_details: null,
    selected_objective_id: -1,          //* initiated from local storage
    selected_objective_details: null,   //* holds Key results for the selected objective
    selected_objective_activities: null,//* ------
    selected_key_result: null,          //* object of selected key result
    selected_key_performance_indicator: null,
    selected_metric: null,

    dropdown_parent_objective: null,    //* ---------------
    selected_activity: null,
    selected_personal_activity: null,            //* object of selected activity plan
    ownerDropdown: null,                //* ----------
    keyResultUnit: null,              //* -------

    company_milestones: [],

    has_loaded_milestones: false,

    lock_level_selection: false,        //* show b-overlay while objective details are loading
    lock_objectives_selection: false,   //* show b-overlay while objective details are loading

    avaliable_shared_graphs:[],         //* array of all available graphs for the shared dataset screen

    is_loading_activity_data: false,
    show_gantt: false,
    show_kanban: false,

    objective_viewing_quarter: 0,
}

export const getters = {


    findObjectiveLevel: (state) => (id) => {
        //! unused
        //console.log('FIND OBJECTIVES IN LEVEL DEBUG', state.activity_data.objectives)
        let final = ''
        let result = state.activity_data.objectives.find(item => {
            return item.id == id})
        let parent = state.activity_data.objectives.find(item => {
            //let id = -1;
            if(result != undefined){
                return item.id == result.parent_objective_id
            }
            else{
                return false;
            }})

            if(parent && !parent.parent_id) final += `${parent.name} > ${result.name}`
            else if(parent) final += `... > ${parent.name} > ${result.name}`
            else{
                if(result != undefined){
                    final += result.name
                }
                else{
                    final;
                }
                
            }

        return final
    },
    getViewingQuarter(state){
        return state.objective_viewing_quarter;
    },
    getQuarterMonthStart(state){
        //? return index of month ( eg jan is 0, feb is 1)
        switch(state.objective_viewing_quarter){
            case 0:{
                //* no quarter - return january
                return 0;
            }
            case 1:{
                //* 1st - return january
                return 0;
            }
            case 2:{
                //* 2nd - return april
                return 3;
            }
            case 3:{
                //* 3rd - return july
                return  6
            }
            case 4:{
                //* 4th - return october
                return  9
            }
            case 5:{
                //* h1 -> jan
                return 0
            }
            case 6:{
                // july
                return 6
            }
        }
    },
    getQuarterlyDateRange(state, getters){
        let current_year = null;
        //eslint-disable-next-line
        let range = {
            start: null,
            end: null,
        }
        if(state.selected_milestone){
            current_year = new Date(state.selected_milestone.year, getters.getQuarterMonthStart);
        }
        else{
            current_year = new Date();
        }
        range.start = current_year;

        if(state.objective_viewing_quarter > 4){
            if(state.objective_viewing_quarter == 5){
                range.end = addMonths(current_year, 6);
            }
            else{
                //* for 2nd half we need to update the start date as well
                //range.start = addMonths(current_year, 6)
                range.end = addMonths(current_year, 6);
            }
        }
        else{
            range.end = addMonths(current_year, 3);
        }
        return range;
    }
}

export const actions = {
    setObjectiveQuarter({commit}, quarter){
        window.localStorage['last_selected_quarter'] = quarter;
        commit('setObjectiveQuarter', quarter);
    },
    clearAll({commit}){
        return new Promise((resolve) => {
            commit('resetStateData', null);
            resolve();
        })
    },
    setOwnerDropdownAction({commit},payload){
        commit('setOwnerDropdown',payload);

    },
    setShowGantt({commit},payload){
        commit('showGantt',payload);
    },
    setShowKanban({commit},payload){
        commit('showKanban',payload);
    },
    //eslint-disable-next-line no-empty-pattern
    saveGuidelineAPI({}, params){
        return new Promise((resolve, reject) => {
            window.axios.post('api/admin/saveguideline/',params)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('saveGuidelineAPI error', error);
                reject();
            })
        })
    },

    resetData({commit}){
        commit('resetStateData');
    },

    resetSelectedObjective({commit}){
        commit('resetObjective');
    },

    //eslint-disable-next-line
    filterObjectivesByQuarter({commit, state}){
        return new Promise((resolve)=>{
            // commit('isLoadingActivityData', true);
            // let objectives_array = [];
            // if(state.objective_viewing_quarter > 0){
            //     objectives_array = state.activity_data.objectives.filter( item => {
            //         const item_date = parseISO(item.start_date);
            //         const item_quarter = getQuarter(item_date);
            //         const quarter_condition = state.objective_viewing_quarter == item_quarter
            //         return quarter_condition;
            //     })
            // }
            // else{
                //objectives_array = state.activity_data.objectives;
            //}
            //store.dispatch('performance/objectives/buildObjectivesTree', objectives_array)
            //.then(()=>{
                //commit('isLoadingActivityData', false);
                resolve();
            //})
        })
    },

    //eslint-disable-next-line
    linkableObjectiveAPI({commit}, params){
        return new Promise((resolve, reject) => {
            window.axios.get('api/levels/linkableobjectives/'+ params)
            .then((res)=>{
                resolve(res.data);
            })
            .catch((error) => {
                //console.log('linkableObjectiveAPI error', error);
                reject(error);
            })
        })
    },
    //eslint-disable-next-line
    loadUserActivityData({commit, state}, user_id){
        commit('isLoadingActivityData', true);
        return new Promise((resolve, reject) => {
            window.axios.get(`api/levels/userview/${user_id}/milestone/${state.pinned_milestone_id}`)
            .then((response)=>{
                if(response.data.objectives.length == 0){
                    commit('setObjective', null);
                }
                if(response.data.objectives){
                    response.data.objectives.forEach((item)=>{
                        item['parent_objective_name'] = item.ancestry ? item.ancestry.name : null;
                        item['parent_objective_id'] = item.ancestry ? item.ancestry.id : null;
                        item['parent_milestone_name'] = item.milestone ? `${item.milestone.name} - ${item.milestone.year}` : 'Not linked to milestone';
                        item['level_milestone_id'] = item.milestone ? item.milestone.id : null;
                    })
                }
                commit('setActivityData', response.data);
                let objectives_array = [];
                if(state.objective_viewing_quarter > 0){
                    //const year = getYear(new Date());
                    objectives_array = state.activity_data.objectives.filter( item => {
                        const item_date = parseISO(item.start_date);
                        const item_quarter = getQuarter(item_date);
                        //const item_year = getYear(item_date);
                        //const year_condition = year == item_year
                        const quarter_condition = state.objective_viewing_quarter == item_quarter
                        //return year_condition && quarter_condition;
                        return quarter_condition;
                    })
                }
                else{
                    objectives_array = state.activity_data.objectives;
                }
                store.dispatch('performance/objectives/buildObjectivesTree', objectives_array)
                .then(()=>{
                    commit('isLoadingActivityData', false);
                    resolve();
                })
            })
            .catch((error) => {
                console.log('Error:', error);
                commit('isLoadingActivityData', false);
                reject();
            })
        })
    },
    //eslint-disable-next-line
    loadActivityData({rootState, state, dispatch, commit}, level_id){

        commit('setLevelLockFlag', true);
        commit('isLoadingActivityData', true)
        //commit('clearObjectives');
        const user_id = rootState.levels.selected_user_id;
        if(user_id == null){
            //* load level data for all users
            return new Promise((resolve, reject) => {
                //axios.get('api/levels/level/' + level_id + '/')
                window.axios(`api/levels/level/${level_id}/milestone/${state.pinned_milestone_id}`)
                .then((response) => {
                    const response_data = response.data;
                    commit('setActivityData', response_data);
                    //* if there are no objectives then release the lock
                    //* else release the lock in the loadObjectiveDetailAPI method
                    if(response_data.objectives.length == 0){
                        commit('setLevelLockFlag', false);
                    }

                    let objectives_array = [];
                    if(state.objective_viewing_quarter > 0){
                        //const year = getYear(new Date());
                        objectives_array = state.activity_data.objectives.filter( item => {
                            const item_date = parseISO(item.start_date);
                            const item_quarter = getQuarter(item_date);
                            //const item_year = getYear(item_date);
                            
                            //const year_condition = year == item_year
                            const quarter_condition = state.objective_viewing_quarter == item_quarter
                            //return year_condition && quarter_condition;
                            return quarter_condition;
                        })
                    }
                    else{
                        objectives_array = state.activity_data.objectives;
                    }
                    store.dispatch('performance/objectives/buildObjectivesTree', objectives_array)
                    .then(()=>{
                        commit('isLoadingActivityData', false)
                        resolve();
                    })
                })
                .catch((error) => {
                    console.log('Error:', error);
                    commit('isLoadingActivityData', false)
                    reject();
                })
            })
        }
        else{
            //* load data for the selected user_id
            dispatch('loadUserActivityData', user_id);
            commit('setLevelLockFlag',  false);
            commit('isLoadingActivityData', false)
        }
    },

    

    //eslint-disable-next-line no-empty-pattern
    saveVision({}, params){
        return new Promise((resolve, reject) => {
            window.axios.post('api/levels/savevision', params)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Vision error', error);
                reject();
            })
        })
    },

    //eslint-disable-next-line no-empty-pattern
    saveVisionCommentAPI({}, params){
        return new Promise((resolve, reject) => {
            window.axios.post('api/levels/savevisioncomment', params)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Mission error', error);
                reject();
            })
        })
    },

    //eslint-disable-next-line no-empty-pattern
    editCommentAPI({}, params){
        return new Promise((resolve, reject) => {
            window.axios.post('api/levels/editcomment', params)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('comment error', error);
                reject();
            })
        })
    },

    //eslint-disable-next-line no-empty-pattern
    deleteCommentAPI({}, params){
        return new Promise((resolve, reject) => {
            window.axios.post('api/levels/deletecomment', params)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('comment error', error);
                reject();
            })
        })
    },





    //eslint-disable-next-line no-empty-pattern
    saveVisionGuidelineAPI({}, params){
        return new Promise((resolve, reject) => {
            window.axios.post('api/levels/savevisionguideline', params)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Mission error', error);
                reject();
            })
        })
    },

    //eslint-disable-next-line no-empty-pattern
    saveMission({}, params){
        return new Promise((resolve, reject) => {
            window.axios.post('api/levels/savemission', params)
            .then((response)=>{
                console.log('SaveMilestone', response)
                resolve();
            })
            .catch((error) => {
                console.log('Mission error', error);
                reject();
            })
        })
    },

    //eslint-disable-next-line no-empty-pattern
    saveMissionGuidelineAPI({}, params){
        return new Promise((resolve, reject) => {
            window.axios.post('api/levels/savemissionguideline', params)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Mission error', error);
                reject();
            })
        })
    },

    //eslint-disable-next-line no-empty-pattern
    saveMissionCommentAPI({}, params){
        return new Promise((resolve, reject) => {
            window.axios.post('api/levels/savemissioncomment', params)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Mission error', error);
                reject();
            })
        })
    },
    //eslint-disable-next-line no-empty-pattern
    saveDefaultMilestone({}, milestone_id){
        return new Promise((resolve, reject) => {
            window.axios.post('api/levels/savedefaultmilestone', { milestone_id: milestone_id})
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Milestone error', error);
                reject(error);
            })
        })
    },
    //eslint-disable-next-line no-empty-pattern
    saveMilestone({}, params){
        return new Promise((resolve, reject) => {
            //! savemilestone spelling error
            window.axios.post('api/levels/savemilestone', params)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Milestone error', error);
                reject(error);
            })
        })
    },

    //eslint-disable-next-line no-empty-pattern
    deleteMilestone({}, params) {
        return new Promise((resolve, reject) => {
            //! savemilestone spelling error
            window.axios.post('api/levels/deletemilestone', params)
            .then((response)=>{
                resolve(response);
            })
            .catch(e => {
                reject(e);
            })
        })
    },

    setObjectiveDetailAPI({commit}){
        commit('setObjectiveDetails', null);
    },
    loadCompanyMilestones({commit}, level_id){
        return new Promise((resolve, reject) => {
            window.axios.get(`api/performance/data`, { params: {
                milestone_data: 1,
                level_id: level_id
            }})
            .then((response) => {
                commit('setCompanyMilestones', response.data.milestones);
                commit('setMilestoneLoadedFlag', true);
                resolve();
            })
            .catch((error) => {
                console.log('Error:', error);
                commit('setMilestoneLoadedFlag', true);
                reject();
            })
        })
    },
    
    loadObjectiveDetailAPI({commit}, params){
        return new Promise((resolve, reject) => {
            //* lock the ovjectives table until data is loaded
            commit('setObjectiveLockFlag', true);
            //axios.get('api/levels/objective/' + params.id + '/' + params.user_id)
            window.axios.get(`api/levels/objective/${params.id}/user/${params.user_id}`)
            .then((response) => {
                let data = response.data;
                commit('setObjectiveDetails', data);
                resolve(data);
                //* Release objective lock flag
                commit('setObjectiveLockFlag', false);
                //* if level selection was locked, unlock it after new data is loaded
                //if(state.lock_level_selection){
                commit('setLevelLockFlag', false);
                //}
            })
            .catch((error) => {
                console.log('Error:', error);
                reject();
            })
        })
    },
    loadObjectiveActivities({commit}, params){
        return new Promise((resolve, reject) => {
            window.axios.get('api/levels/objectiveactions/'+params.objective_id+'/'+params.user_id)
            .then((response) => {
                commit('setObjectiveActivities', response.data);
                resolve();
            })
            .catch((error) => {
                console.log('Error:', error);
                reject();
            })
        })
    },



    //eslint-disable-next-line no-empty-pattern
    saveObjectiveAPI({}, params){
        return new Promise((resolve, reject) => {
            window.axios.post('api/levels/saveobjective', params)
            .then((response)=>{
                //console.log('saveObjectiveAPI')
                //console.log(state.selected_objective)
                resolve(response.data);
            })
            .catch((error) => {
                console.log('Objective error', error);
                reject();
            })
        })
    },
    //eslint-disable-next-line no-empty-pattern
    deleteObjective({}, payload){
        return new Promise((resolve, reject) => {
            window.axios.post('api/levels/deleteobjective', {ids:payload})
            .then((result)=>{
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            })
        })
    },
    //eslint-disable-next-line no-empty-pattern
    forceDeleteObjective({}, payload){
        return new Promise((resolve, reject) => {
            window.axios.post('api/levels/deleteobjective', {ids: payload, force: true})
            .then((result)=>{
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            })
        })
    },

    //eslint-disable-next-line no-empty-pattern
    saveObjectiveGuidelineAPI({}, id){
        return new Promise((resolve, reject) => {
            window.axios.post('api/levels/saveobjectiveguideline', {id:id})
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Delete error', error);
                reject();
            })
        })
    },





    //eslint-disable-next-line no-empty-pattern
    deleteKeyResult({}, id){
        return new Promise((resolve, reject) => {
            window.axios.post('api/levels/deletekeyresult', {id:id})
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Delete error', error);
                reject();
            })
        })
    },

    //eslint-disable-next-line no-empty-pattern
    saveObjectivePlan({}, params){
        return new Promise((resolve, reject) => {
            window.axios.post('api/levels/saveobjectiveplan', params)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Objective error', error);
                reject();
            })
        })
    },
    //eslint-disable-next-line no-empty-pattern
    deleteObjectivePlan({}, params){
        return new Promise((resolve, reject) => {
            window.axios.post('api/levels/saveobjectiveplan', params)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Objective error', error);
                reject();
            })
        })
    },

    //eslint-disable-next-line no-empty-pattern
    getKeyResultUnitAPI({commit}){
        return new Promise((resolve, reject) => {
            window.axios.get('api/levels/unit_types')
            .then(res=>{
                commit('setKeyResultUnit', res.data);
                resolve();
            })
            .catch((error) => {
                console.log('Key Result error', error);
                reject();
            })
        })
    },

    //eslint-disable-next-line no-empty-pattern
    saveKeyResult({}, params){
        return new Promise((resolve, reject) => {
            window.axios.post('api/levels/savekeyresult', params)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Key Result error', error);
                reject();
            })
        })
    },
    //eslint-disable-next-line
    saveKeyResultTracking({}, params){
        return new Promise((resolve, reject) => {
            window.axios.post('api/levels/savekeyresulttracking', params)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Key Result Tracking error', error);
                reject();
            })
        })
    },


    //eslint-disable-next-line no-empty-pattern
    saveValidation({}, params){
        return new Promise((resolve, reject) => {
            window.axios.post('api/levels/savevalidation', params)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('SMART validation error', error);
                reject();
            })
        })
    },

    setPinnedMilestoneId({commit}, milestone_id){
        return new Promise(resolve => {
            if(milestone_id == null){
                //* remove quarter if milestone is null
                commit('setObjectiveQuarter', 0);
            }
            commit('setPinnedMilestone', milestone_id);
            resolve();
        })
    },
    setSelectedMilestone({commit}, milestone){
        return new Promise(resolve => {
            commit('setMilestone', milestone);
            resolve();
        })
    },
    setSelectedObjective({commit}, objective){
        return new Promise(resolve => {
            commit('setObjectives', []);
            commit('setObjective', objective);
            resolve();
        })
    },
    setSelectedObjectives({commit}, payload){
        return new Promise((resolve, reject) => {
            commit('setObjectives', payload.rows);
            let ids = [];
            let params = {
                user_id: payload.user_id,
                objectives: ids,
            }
            payload.rows.forEach( item => {
                ids.push(item.id);
            })
            window.axios.post(`api/levels/selectedobjectives`, params)
            .then((response) => {

                let objective_details = {
                    metrics: [],
                    key_results: [],
                    activity_plans: [],
                }
                response.data.forEach( (objective) => {
                    //* merge all the required arrays from each objective returned
                    objective_details.metrics = [ ...objective_details.metrics, ...objective.operational_metrics];
                    objective_details.key_results = [ ...objective_details.key_results, ...objective.key_results];
                    objective_details.activity_plans = [ ...objective_details.activity_plans, ...objective.plan_data];
                });
                commit('setMultipleObjectiveDetails', objective_details)
                resolve();
            })
            .catch((e)=>{
                console.log(e)
                reject();
            })
        })
    },
    setSelectedKeyResult({commit}, key_result){
        return new Promise(resolve => {
            commit('setKeyResult', key_result);
            resolve();
        })
    },
    setSelectedKPI({commit}, kpi){
        return new Promise(resolve => {
            commit('setKPI', kpi);
            resolve();
        })
    },
    setSelectedMetric({commit}, metric){
        return new Promise(resolve => {
            commit('setMetric', metric);
            resolve();
        })
    },
    setSelectedActivity({commit}, activity){
        commit('setActivity', activity);
    },
    setSelectedPersonalActivity({commit}, activity){
        commit('setPersonalActivity', activity);
    },
    clearCompanyMilestones({commit}){
        commit('setCompanyMilestones', []);
    },
    clearData({commit}, type = 'all'){
        return new Promise(resolve => {
            if(type == 'all'){
                commit('setActivityData', null);
                commit('setObjective', null);
                commit('setObjectiveDetails', null);
                commit('setObjectiveActivities', null)
                commit('setKeyResult', null);
                //commit('setMilestone', null);
            }
            else if(type == 'milestone'){
                commit('setMilestone', null);
            }
            else if(type == 'objective'){
                commit('setObjective', null);
            }
            else if(type == 'key'){
                commit('setKeyResult', null);
            }

            resolve();
        })
    },

    /* RISKS */
    //eslint-disable-next-line no-empty-pattern
    saveObjectiveRisk({}, params){//? id, objective_id, name, date, likelyhood, impact */
        return new Promise((resolve, reject) => {
            window.axios.post('api/org/save_risks', params)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Error', error);
                reject();
            })
        })
    },
    //eslint-disable-next-line no-empty-pattern
    deleteObjectiveRisk({}, params){//? objective_id */
        return new Promise((resolve, reject) => {
            window.axios.post('api/org/delete_risk', params)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Error', error);
                reject();
            })
        })
    },
    //* PLANS
    //eslint-disable-next-line no-empty-pattern
    saveObjectiveAction({}, params){
        return new Promise((resolve, reject) => {
            window.axios.post('api/levels/save_key_actions', params)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Error', error);
                reject();
            })
        })
    },


    //eslint-disable-next-line no-empty-pattern
    deleteObjectiveTask({}, params){
        return new Promise((resolve, reject) => {
            window.axios.post('api/levels/delete_key_action', params)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Error', error);
                reject();
            })
        })
    },




    //eslint-disable-next-line
    linkKpi({}, params){
        /* kpi_id, level_id, key_result_id, objective_id */
        return new Promise((resolve, reject) => {
            window.axios.post('api/levels/linkkpi', params)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Error', error);
                reject();
            })
        })
    },

    //eslint-disable-next-line
    saveKpi({}, params){
        /* id, level_id, key_result_id, objective_id, name, description, time_period, unit_type, min_value, max_value */
        return new Promise((resolve, reject) => {
            window.axios.post('api/levels/savekpi', params)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Error', error);
                reject();
            })
        })
    },
    //eslint-disable-next-line
    saveKpiTracking({}, params){
        /* kpi_id, achieved_date, value */
        return new Promise((resolve, reject) => {
            window.axios.post('api/levels/savekpitracking', params)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Error', error);
                reject();
            })
        })
    },
    //eslint-disable-next-line
    deleteKpi({}, params){
        /* kpi_id */
        return new Promise((resolve, reject) => {
            window.axios.post('api/levels/deletekpi', params)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Error', error);
                reject();
            })
        })
    },
    //eslint-disable-next-line
    tester(){
        alert('soup');
    },


    //* Operational metrics
    /*
        /api/levels/savemetric (POST)
        /api/levels/linkmetric (POST)
        /api/levels/savemetrictracking (POST)
        /api/levels/sharemetric (POST)
        /api/levels/deletemetric (POST)
*/
    //eslint-disable-next-line
    saveMetric({}, params){
        /* id, level_id, key_result_id, objective_id, name, description, time_period, unit_type, min_value, max_value */
        return new Promise((resolve, reject) => {
            window.axios.post('api/levels/savemetric', params)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Error', error);
                reject();
            })
        })
    },
    //eslint-disable-next-line
    saveMetricTracking({}, params){
        /* metric_id, achieved_date, value */
        return new Promise((resolve, reject) => {
            window.axios.post('api/levels/savemetrictracking', params)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Error', error);
                reject();
            })
        })
    },
    //eslint-disable-next-line
    deleteMetric({}, params){
        /* metric_id */
        return new Promise((resolve, reject) => {
            window.axios.post('api/levels/deletemetric', params)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Error', error);
                reject();
            })
        })
    },
    //eslint-disable-next-line
    linkMetric({}, params){
        return new Promise((resolve, reject) => {
            window.axios.post('api/levels/linkmetric', params)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Error', error);
                reject();
            })
        })
    },



    //* traverse
    //eslint-disable-next-line
    traverseKeyResult({}, params){
        return new Promise((resolve, reject) => {
            window.axios.post('api/levels/traversekeyresult', params)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Error', error);
                reject();
            })
        })
    },
    //eslint-disable-next-line
    traverseKPI({}, params){
        return new Promise((resolve, reject) => {
            window.axios.post('api/levels/traversekpi', params)
            .then((response)=>{
                resolve(response.data);
            })
            .catch((error) => {
                console.log('Error', error);
                reject();
            })
        })
    },
    //eslint-disable-next-line
    traverseMetric({}, params){
        return new Promise((resolve, reject) => {
            window.axios.post('api/levels/traversemetric', params)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Error', error);
                reject();
            })
        })
    },

    mutateGraphData({commit}, params){
        return new Promise((resolve) => {
            commit('mutateKpiGraph', params);
            resolve();
        })
    },

    //* activity stuff
    //eslint-disable-next-line
    saveActivityInbox({}, params){
        return new Promise((resolve, reject) => {
            window.axios.post('api/levels/save_key_actions', params)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Error', error);
                reject();
            })
        })
    },
    //eslint-disable-next-line
    saveActivityInboxStatus({}, params){
        return new Promise((resolve, reject) => {
            window.axios.post('api/levels/saveinboxstatus', params)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Error', error);
                reject();
            })
        })
    },

    updateObjectiveProgress({commit}, objective){
        commit('updateObjectiveProgress', objective);
    },
    
    //* reordering
    //eslint-disable-next-line
    updateObjectiveRowOrder({}, sortData){
        return new Promise((resolve, reject) => {
            window.axios.post('api/admin/re-order/objective', {sortData: sortData})
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Error', error);
                reject();
            })
        })
    },
    //eslint-disable-next-line
    updateRowOrder({}, payload){
        return new Promise((resolve, reject) => {
            window.axios.post(`api/admin/re-order/${payload.type}`, {sortData: payload.sortData})
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Error', error);
                reject();
            })
        })
    },
    //eslint-disable-next-line no-empty-pattern
    updateItemOrder({}, payload){//payload:{type, data}
        return new Promise((resolve, reject) => {
            window.axios.post(`api/admin/re-order/${payload.type}`, {sortData: payload.data})
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Error', error);
                reject();
            })
        })
    },


    //* ------------------------------------------------------------------------------
    //*     Shared Dataset Stuff
    //* ------------------------------------------------------------------------------
    loadAvailableKeyResultDataset({commit}, payload){ // has key_result_id and frequency
        return new Promise((resolve, reject) => {
            commit('setAvailableSharedGraphs', [])
            window.axios.get(`api/performance/sharable_krs/${payload.key_result_id}/frequency/${payload.frequency}`)
            .then((response)=>{
                commit('setAvailableSharedGraphs', response.data)
                resolve();
            })
            .catch((error) => {
                console.log('Error', error);
                reject();
            })
        })
    },
    loadAvailableTargetDataset({commit}, payload){ // has kpi_id and frequency
        return new Promise((resolve, reject) => {
            commit('setAvailableSharedGraphs', [])
            window.axios.get(`api/performance/sharable_targets/${payload.target_id}/frequency/${payload.frequency}`)
            .then((response)=>{
                commit('setAvailableSharedGraphsKPIS', response.data)
                resolve();
            })
            .catch((error) => {
                console.log('Error', error);
                reject();
            })
        })
    },
    loadAvailableKPIDataset({commit}, payload){ // has kpi_id and frequency
        return new Promise((resolve, reject) => {
            commit('setAvailableSharedGraphs', [])
            window.axios.get(`api/performance/sharable_kpis/${payload.kpi_id}/frequency/${payload.frequency}`)
            .then((response)=>{
                commit('setAvailableSharedGraphsKPIS', response.data)
                resolve();
            })
            .catch((error) => {
                console.log('Error', error);
                reject();
            })
        })
    },
    loadAvailableMetricDataset({commit}, payload){ // has metric_id and frequency
        return new Promise((resolve, reject) => {
            commit('setAvailableSharedGraphs', [])
            window.axios.get(`api/performance/sharable_metrics/${payload.metric_id}/frequency/${payload.frequency}`)
            .then((response)=>{
                commit('setAvailableSharedGraphs', response.data)
                resolve();
            })
            .catch((error) => {
                console.log('Error', error);
                reject();
            })
        })
    },
    // eslint-disable-next-line
    saveSharedTargetGraph({}, payload){
        return new Promise((resolve, reject) => {
            window.axios.post('api/performance/add_shared_target', payload)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Error', error);
                reject();
            })
        })
    },
    // eslint-disable-next-line
    saveMultilineTargetGraph({}, payload){
        return new Promise((resolve, reject) => {
            window.axios.post('api/performance/save_multiline_target', payload)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Error', error);
                reject();
            })
        })
    },

    // eslint-disable-next-line
    saveSharedKeyResultGraph({}, payload){
        return new Promise((resolve, reject) => {
            window.axios.post('api/performance/add_shared_kr', payload)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Error', error);
                reject();
            })
        })
    },
    // eslint-disable-next-line
    saveMultilineKeyResultGraph({}, payload){
        return new Promise((resolve, reject) => {
            window.axios.post('api/performance/save_multiline_kr', payload)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Error', error);
                reject();
            })
        })
    },
    // eslint-disable-next-line
    saveSharedKpiGraph({}, payload){
        return new Promise((resolve, reject) => {
            window.axios.post('api/performance/add_shared_kpi', payload)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Error', error);
                reject();
            })
        })
    },
    // eslint-disable-next-line
    saveMultilineKPIGraph({}, payload){
        return new Promise((resolve, reject) => {
            window.axios.post('api/performance/save_multiline_kpi', payload)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Error', error);
                reject();
            })
        })
    },
    // eslint-disable-next-line
    saveSharedMetricGraph({}, payload){
        return new Promise((resolve, reject) => {
            window.axios.post('api/performance/add_shared_metric', payload)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Error', error);
                reject();
            })
        })
    },
    // eslint-disable-next-line
    saveMultilineMetricGraph({}, payload){
        return new Promise((resolve, reject) => {
            window.axios.post('api/performance/save_multiline_metric', payload)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Error', error);
                reject();
            })
        })
    },

    // eslint-disable-next-line
    createUnitType({}, payload){
        return new Promise((resolve, reject) => {
            window.axios.post('api/admin/unit_type', payload)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Error', error);
                reject();
            })
        })
    },
    // eslint-disable-next-line
    saveUnitTypes({}, payload){
        return new Promise((resolve, reject) => {
            //*payload = units:[ {id:1,unit:'Toes'} ]
            window.axios.post('api/admin/save_units', payload)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Error', error);
                reject();
            })
        })
    },
    // eslint-disable-next-line
    deleteUnitTypes({}, payload){
        return new Promise((resolve, reject) => {
            //*payload = units:[ {id:1,unit:'Toes'} ]
            window.axios.post('api/admin/unit/delete', {unit_type_ids: payload})
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Error', error);
                reject(error);
            })
        })
    }
}

export const mutations = {
    setActivityData(state, payload){
        state.activity_data = payload;
    },
    setOwnerDropdown(state, payload){
        state.ownerDropdown = payload;
    },

    setMilestone(state, payload){
        state.selected_milestone = payload;
    },
    setObjective(state, payload){
        //console.log('SET OBJECTIVE PAYLOAD', payload)
        state.multiple_selected_objective_details = null;
        state.selected_objective = payload;
        if(payload == null){
            state.selected_objective_details = null;
        }
        if(state.selected_objective?.id){
            state.selected_objective_id = state.selected_objective.id;
            window.localStorage.setItem('objective_id', state.selected_objective.id);
        }
        //else{
        //    state.selected_objective_id = -1;
        //    window.localStorage.removeItem('objective_id');
        //}
    },
    setObjectives(state, payload){
        state.selected_objectives = payload;
        if(payload.length == 0){
            state.multiple_selected_objective_details = null;
        }
    },
    setMultipleObjectiveDetails(state, payload){
        state.selected_objective_id = -1;
        state.selected_objective = null;
        state.multiple_selected_objective_details = payload;
    },
    setObjectiveDetails(state, payload){
        state.selected_objective_details= payload;
    },
    setObjectiveActivities(state, payload){
        state.selected_objective_activities = payload;
    },
    setKeyResult(state, payload){
        if(payload && payload.multi_line){
            payload.share_type = 'multi-line';
            //* update the share type as 'multi-line' for the ui
        }
        state.selected_key_result = payload;
        
    },
    setKPI(state, payload){
        if(payload && payload.multi_line){
            payload.share_type = 'multi-line';
            //* update the share type as 'multi-line' for the ui
        }
        state.selected_key_performance_indicator = payload;
    },
    setMetric(state, payload){
        if(payload && payload.multi_line){
            payload.share_type = 'multi-line';
            //* update the share type as 'multi-line' for the ui
        }
        state.selected_metric = payload;
    },
    setDropdownParentObjective(state, payload){
        state.dropdown_parent_objective = payload

    },
    resetStateData(state){      
        state.activity_data = null;
        state.selected_milestone = null;
        state.selected_objective = null;
        state.selected_objective_details = null;
        state.selected_objective_activities = null;
        state.selected_key_result = null;
        state.dropdown_parent_objective = null;
        state.selected_activity = null;
        state.ownerDropdown = null;
        state.pinned_milestone_id = -1
        //state.keyResultUnit = null;
    },
    resetObjective(state){
        state.selected_objective = null;
        state.selected_objective_id = -1;
        state.selected_objective_details = null;
        state.selected_key_result = null;
        state.selected_key_performance_indicator = null;
        state.selected_metric = null;
    },
    setKeyResultUnit(state, payload){
        //let data = [];
        //for (const [key, value] of Object.entries(payload)) {
        //    data.push({
        //        name: key,
        //        id: value,
        //    })
        //}
        //state.keyResultUnit = data;
        state.keyResultUnit = payload.map( unit_type => {
            return {
                id: unit_type.id,
                cumulative: unit_type.cumulative,
                name: unit_type.unit
            }
        })
    },
    setActivity(state, payload){
        state.selected_activity = payload;
    },
    setPersonalActivity(state, payload){
        state.selected_personal_activity = payload;
    },

    mutateKpiGraph(state, payload){
        //! must delete later
        alert(payload.new_time_period);
        alert(payload.id)
        let index = _.findIndex(state.selected_objective_details.kpis, { id: payload.id} );
        alert(index)
        if(index != -1){
            state.selected_objective_details.kpis[index].current_time_period = payload.new_time_period;
            state.selected_objective_details.kpis[index].tracking = payload.array;
        }
    },

    updateObjectiveProgress(state, payload){
        let index = _.findIndex(state.activity_data.objectives, {id: payload.id});
        if(index != -1){
            state.activity_data.objectives[index].progress = payload.progress;
        }
    },

    // eslint-disable-next-line
    setLevelLockFlag(state, flag){
        state.lock_level_selection = false
        //state.lock_level_selection = flag;
    },
    setObjectiveLockFlag(state, flag){
        state.lock_objectives_selection = flag;
    },
    setCompanyMilestones(state, data){
        state.company_milestones = data;
    },
    setMilestoneLoadedFlag(state, flag){
        state.has_loaded_milestones = flag;
    },

    setPinnedMilestone(state, id){
        window.localStorage.setItem('milestone_id', id)
        state.pinned_milestone_id = id;
    },


    setAvailableSharedGraphs(state, data){
        state.avaliable_shared_graphs = data;
    },
    setAvailableSharedGraphsKPIS(state, data){
        state.avaliable_shared_graphs = data;
    },
    setAvailableSharedGraphsMetrics(state, data){
        state.avaliable_shared_graphs = data;
    },

    clearObjectives(state){
        if(state.activity_data != null){
            state.activity_data.objectives = [];
        }
    },

    isLoadingActivityData(state, payload){
        state.is_loading_activity_data = payload;
    },

    showGantt(state, payload){
        state.show_gantt = payload;
    },

    showKanban(state, payload){
        state.show_kanban = payload;
    },

    setObjectiveQuarter(state, payload){
        state.objective_viewing_quarter = payload;
    },



    setSelectedMilestoneID(state, value){
        console.log(state, value)
        //state.selected_objective_id = value;
    },
    setInitialObjectiveId(state, value){
        state.selected_objective_id = value;
    },
}
