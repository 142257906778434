
export const state = {
    row1: {
        kpi: 'default', //'default', 'expanded', 'dataset'
    },
    row2:{
        kpi: 'default', //'default', 'expanded', 'dataset'
        kr: 'default' //'default', 'expanded', 'dataset'
    },
};

export const mutations = {
    updateHeightState(state, payload) {
        state.row2[payload.type] = payload.size;
    },

    updateKpiState(state, payload) {
        state.row1[payload.type] = payload.size;
    },
};

export const actions = {
    setNewHeightState({commit}, state){
        commit('updateHeightState',state);
    },
    setKpiHeight({commit}, state){
        commit('updateKpiState',state);
    }
    /*fetchTodos({ commit }) {
        axios.get('https://jsonplaceholder.typicode.com/todos').then(res => {
            commit('setTodos', res.data)
        })
    },*/
};
