
export const state = {
    meeting_objectives:[],
    meetings:[],
    meeting_data : null,
    selected_meeting_id: -1,
    selected_meeting_objective_id: -1,
    meeting_activity_plans: [],
    recipient_types: [],

    is_loading_objectives: false,
    is_loaidng_meetings: false,
    is_loading_activities: false,

}

export const getters = {
    selected_meeting: (state) => () => {
        let meeting = state.meetings.find( meeting => {
            return meeting.id == state.selected_meeting.id;
        })
        if(meeting){
            return meeting;
        }
        else{
            return null;
        }
    },
}

export const actions = {
    //eslint-disable-next-line
    createMeeting({}, payload){
        //eslint-disable-next-line
        return new Promise((resolve, reject) => {
            window.axios.post('api/meetings/newmeeting', payload)
            .then(()=>{
                //commit('setMeetingsData', response.data);
                resolve();
            })
            .catch(()=>{
                reject();
            })
        })
    },
    //eslint-disable-next-line
    updateMeeting({}, payload){
        //eslint-disable-next-line
        return new Promise((resolve, reject) => {
            window.axios.post('api/meetings/updatemeeting', payload)
            .then(()=>{
                //commit('setMeetingsData', response.data);
                resolve();
            })
            .catch(()=>{
                reject();
            })
        })
    },
    loadUserMeetingObjectives({commit}, user_id){
        commit('setLoadingState', { type: 'objective', state: true });
        return new Promise((resolve, reject) => {
            //axios.get( base + 'api/levels/level/' + level_id + '/')
            window.axios(`api/levels/userview/${user_id}/milestone/-1`)
            .then((response) => {
                let response_data = response.data;
                if(response_data && response_data.objectives){
                    commit('setMeetingObjectives', response_data.objectives);
                }
                else{
                    commit('setMeetingObjectives', []);
                }
                commit('setLoadingState', { type: 'objective', state: false });
                resolve();
            })
            .catch((error) => {
                console.log('Error:', error);
                commit('setLoadingState', { type: 'objective', state: false });
                reject();
            })
        })
    },
    loadMeetingObjectives({rootState, commit}, level_id){
        //alert('Pinned m ' + rootState.activity.pinned_milestone_id);

        commit('setLoadingState', { type: 'objective', state: true });
        return new Promise((resolve, reject) => {
            //axios.get( base + 'api/levels/level/' + level_id + '/')
            window.axios(`api/levels/level/${level_id}/milestone/${rootState.activity.pinned_milestone_id}`)
            .then((response) => {
                let response_data = response.data;
                if(response_data && response_data.objectives){
                    commit('setMeetingObjectives', response_data.objectives);
                }
                else{
                    commit('setMeetingObjectives', []);

                }
                commit('setLoadingState', { type: 'objective', state: false });
                resolve();
            })
            .catch((error) => {
                console.log('Error:', error);
                commit('setLoadingState', { type: 'objective', state: false });
                reject();
            })
        })
    },
    loadMeetingActivities({commit}, params){
        return new Promise((resolve, reject) => {
            window.axios.get(`api/meetings/getmeetingactions`, {params: params})
            .then((response) => {
                commit('setMeetingActivityPlans', response.data.actions);
                resolve();
            })
            .catch((error) => {
                console.log('Error:', error);
                reject();
            })
        })
    },
    //eslint-disable-next-line
    deleteMeeting({}, id){
        return new Promise((resolve, reject) => {
            window.axios.post('api/meetings/deletemeeting', id)
            .then((response)=>{
                //commit('setMeetingsData', response.data);
                resolve(response);
            })
            .catch(()=>{
                reject();
            })
        })
    },
    //eslint-disable-next-line
    saveMeetingActivity({}, payload){
        //eslint-disable-next-line
        return new Promise((resolve, reject) => {
            window.axios.post('api/levels/saveobjectiveaction', payload)
            .then(()=>{
                //commit('setMeetingsData', response.data);
                resolve();
            })
            .catch(()=>{
                reject();
            })
        })
    },
    loadMeetingData({commit}, id){
        commit('setLoadingState', { type: 'meeting', state: true });
        return new Promise((resolve, reject) => {
            window.axios.get(`api/meetings/getmeeting`, {params : {id: id}})
            .then((response)=>{
                commit('setMeetingData', response.data.meeting);
                commit('setLoadingState', { type: 'meeting', state: false });
                resolve();
            })
            .catch(()=>{
                commit('setLoadingState', { type: 'meeting', state: false });
                reject();
            })
        })
    },
        //eslint-disable-next-line
    getMeetingNotes({commit}, payload){
        return new Promise((resolve, reject) => {
            window.axios.get(`api/meetings/getmeetingnotes`, {params : payload})
            .then(()=>{
                //commit('setMeetingData', response.data.meeting);
                resolve();
            })
            .catch(()=>{
                reject();
            })
        })
    },
    //eslint-disable-next-line
    saveMeetingNotes({}, payload){
        return new Promise((resolve, reject) => {
            window.axios.post('api/meetings/savemeetingnotes', payload)
            .then(()=>{
                resolve();
            })
            .catch(()=>{
                reject();
            })
        })
    },
    //eslint-disable-next-line
    loadRecipientTypes({commit}){
        return new Promise((resolve, reject) => {
            window.axios.get(`api/scheduler/cron/recipienttypes`)
            .then((response)=>{
                commit('setRecipientTypes', response.data);
                //console.log('Load recipients', response.data)
                resolve();
            })
            .catch(()=>{
                reject();
            })
            //
        })
    },
    //eslint-disable-next-line
    loadMeetings({commit}, params){ // user_id - start_date - end_date
        //if(should_clear){
        //    commit('setMeetingsData', []);
        //}
        //eslint-disable-next-line
        return new Promise((resolve, reject) => {
            //commit('setMeetingsData', [
            //    {id:1, title: 'Debug', linked_objectives: [],attendees:[], meeting_time: '10:10:10',},
            //]);
            //resolve();
            window.axios.get(`api/meetings/getmeetings`, {params: params})
            .then((response)=>{
                commit('setMeetingsData', response.data.meetings);
                resolve();
            })
            .catch(()=>{
                reject();
            })
        })
    },
    //eslint-disable-next-line
    linkActionsToMeetings({}, params){
        return new Promise((resolve, reject) => {
            window.axios.post('api/meetings/updatemeetingactions', params)
            .then(()=>{
                resolve();
            })
            .catch(()=>{
                reject();
            })
        })
    },
    //eslint-disable-next-line
    addMeetingReminder({}, payload){
        return new Promise((resolve, reject) => {
            window.axios.post('api/scheduler/cron/create', payload)
            .then(()=>{
                resolve();
            })
            .catch(()=>{
                reject();
            })
        })
    },
    //eslint-disable-next-line
    updateMeetingReminder({}, payload){
        return new Promise((resolve, reject) => {
            window.axios.post(`api/scheduler/cron/update`, payload)
            .then(()=>{
                resolve();
            })
            .catch(()=>{
                reject();
            })
        })
    },
    //eslint-disable-next-line
    deleteMeetingReminder({}, id){
        return new Promise((resolve, reject) => {
            window.axios.post(`api/scheduler/cron/delete`, {id : id})
            .then(()=>{
                resolve();
            })
            .catch(()=>{
                reject();
            })
        })
    },
    setSelectedMeetingID({commit}, id){
        return new Promise( resolve => {
            commit('setSelectedMeetingId', id);
            commit('setSelectedMeetingObjectiveId', -1);
            resolve();
        })
    },
    setSelectedMeetingObjectiveID({commit}, id){
        return new Promise( resolve => {
            //commit('setSelectedMeetingId', -1);
            commit('setSelectedMeetingObjectiveId', id);
            resolve();
        })
    },
    clearMeetingStore({commit}){
        commit('clearMeetingStore');
    },
}

export const mutations = {
    setMeetingObjectives(state, payload){
        state.meeting_objectives = payload;
    },
    setMeetingsData(state, payload){
        state.meetings = payload;
    },
    setMeetingData(state, payload){
        state.meeting_data = payload;
    },
    setMeetingActivityPlans(state, payload){
        state.meeting_activity_plans = payload;
    },
    setSelectedMeetingId(state, payload){
        state.selected_meeting_id = payload;
        
    },
    setSelectedMeetingObjectiveId(state, payload){
        state.selected_meeting_objective_id = payload;
        if(payload == -1){
            state.meeting_activity_plans = [];
        }
    },
    setRecipientTypes(state, payload){
        state.recipient_types = payload
    },
    setLoadingState(state, payload){
        switch(payload.type){
            case 'meeting':{
                state.is_loaidng_meetings = payload.state;
                break;
            }
            case 'objective':{
                state.is_loading_objectives = payload.state;
                break;
            }
            case 'activity':{
                state.is_loading_activities = payload.state;
                break;
            }
        }
    },
    clearMeetingStore(state){
        state.meetings = [];
        state.meeting_data = null,
        state.selected_meeting_id = -1;
        state.selected_meeting_objective_id = -1;
        state.meeting_objectives = [];
        state.meeting_activity_plans = [];
        state.is_loaidng_meetings = false;
        state.is_loading_objectives = false;
        state.is_loading_activities = false;
    },
}