
import { differenceInDays } from 'date-fns'
export const state = {
    gantt_pinned_id: -1,
    gantt_tasks:[],
    pinned_gantt_tasks: [], // i cry -- 
    kanban_data: [],
    projects: [],
    selected_project_id: -1,
    loaded_kanban_config: null,
    gantt_modal_data: null,
    gantt_selected_objective_id: -1,
    gantt_selected_item_type: '',
    gantt_height: null,
    gantt_column_config: [],
    gantt_kanban_loading: false,
    is_loading_kanban_data: false,
    action_plan_modal: null,
    saved_key_action: false,
    hide_gantt: false,
    fullscreen_kanban: false,
    hide_projects: false,
    refresh_kanban: false
}

export const actions = {
    setPinnedGanttItems({ commit}, payload){
        let array_data = payload.array_data;
        let gantt_pinned_id = payload.gantt_pinned_id;
        const flatten = (members) => {
            let data = [];
            return members.map(m => {
                if (m.data && m.data.length) {
                    data = [...data, ...m.data];
                }
                return m;
            }).concat(data.length ? flatten(data) : data);
        };
        let flat_array = flatten(array_data)
        commit('setPinnedGanttID', gantt_pinned_id)
        commit('setPinnedGanttItems', flat_array);
    },
    clearPinnedGanttItems({commit}){
        commit('setPinnedGanttID', -1)
        commit('setPinnedGanttItems', []);
    },
    // eslint-disable-next-line
    resetGanttKanban({commit}){
        commit('resetGanttKanban');
    },
    // eslint-disable-next-line
    savedKeyAction({commit}, params){
        commit('setSaveKeyAction', params);
    },
    // eslint-disable-next-line
    hideGantt({commit}, params){
        commit('setGanttHidden', params);
    },
    // eslint-disable-next-line
    hideProjects({commit}, params){
        commit('setProjectsHidden', params);
    },
    // eslint-disable-next-line
    fullscreenKanban({commit}, params){
        commit('setfullscreenKanban', params);
    },
    // eslint-disable-next-line
    refreshKanban({commit}, params){
        commit('setRefreshKanban', params);
    },
    // eslint-disable-next-line
    moveKanbanListItem({}, kanban_payload){
        return new Promise( (resolve, reject) => {
            window.axios.post(`api/gantt/movekanbanlistitem`, kanban_payload)
            .then(()=>{ resolve(); })
            .catch(()=>{ reject(); })
        })
    },
    //modal_data can be object, or null to clear.
    showGanttModal({commit}, modal_data){
        commit('setGanttModalData', modal_data);
    },
    selectGanttObjectiveRow({commit}, selection_params){
        commit('setGanttSelectedObjective', selection_params);
    },
    selectGanttHeight({commit}, selection_params){
        commit('setGanttHeight', selection_params);
    },
    showColumnConfig({commit}, modal_data){
        commit('setColumnConfig', modal_data);
    },
    //* load data for gantt chart
    loadGanttData({commit}, params){ // params => {user_id, level_id}
        return new Promise( (resolve, reject) => {
            commit('setLoadingGanttAndKanban', true);
            window.axios.get(`api/gantt/data`, {params: params})
            .then(( response )=>{
                //let sorted_list = orderBy(response.data, ['id'],['asc']);
                commit('setGanttData', response.data);
                commit('setLoadingGanttAndKanban', false);
                resolve();
            })
            .catch(()=>{
                commit('setLoadingGanttAndKanban', false);
                reject();
            })
        })
    },
    loadGanttKanbanData({commit}, params){
        commit('setKanbanLoadingState', true);
        return new Promise( (resolve, reject) => {
            window.axios.get(`api/gantt/ganttkanban`, {params: params})
            .then(( response )=>{
                commit('setKanbanData', response.data);
                commit('setKanbanLoadingState', false);
                resolve();
            })
            .catch(( error )=>{
                console.log('ERROR', error)
                commit('setKanbanLoadingState', false);
                reject();
            })
        })
    },
    setKanbanLoadingState({commit}, state){
        commit('setKanbanLoadingState', state);
    },
    selectProjectID({commit}, selection_params){
        commit('setProjectID', selection_params);
    },
    //eslint-disable-next-line
    getClientProjects({commit}, params) {
        console.log(params)
        // let main_params = {
        //     item_id: params.item_id,
        //     item_type: params.item_type,
        //     user_id: params.user_id, 
        // }
        return new Promise((resolve, reject)=>{
            window.axios.get('api/gantt/projects', { params: params })
            .then((response)=>{
                commit('setClientProjects', response.data);
                resolve();
            })
            .catch(()=>{
                reject();
            })
        })
    },
    //eslint-disable-next-line
    saveClientProject({}, params){
        return new Promise((resolve, reject) => {
            window.axios.post('api/gantt/save_projects', params)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Error', error);
                reject();
            })
        })
    },
    //eslint-disable-next-line
    deleteClientProject({}, params){
        return new Promise((resolve, reject) => {
            window.axios.post('api/gantt/delete_project', params)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Error', error);
                reject();
            })
        })
    },
    //eslint-disable-next-line
    saveResponsibleProjectUsers({}, params){
        return new Promise((resolve, reject) => {
            window.axios.post('api/gantt/save_project_users', params)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Error', error);
                reject();
            })
        })
    },
    //eslint-disable-next-line
    linkProjectKA({}, params){
        return new Promise((resolve, reject) => {
            window.axios.post('api/gantt/link_project_ka', params)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Error', error);
                reject();
            })
        })
    },
    //eslint-disable-next-line
    unLinkProjectKA({}, params){
        return new Promise((resolve, reject) => {
            window.axios.post('api/gantt/unlink_project_ka', params)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Error', error);
                reject();
            })
        })
    },
    /*loadGanttData({commit},  ids = []){
        if(ids.length < 1){
            console.log('Using hardcoded ids')
        }
        //! TEMP - get all Apollo Objectives
        let hardcoded_objective_ids = [ 252, 260, 300, 261, 442, 443, 444, 314, 438, 449, 453, 375, 376, 377, 379, 380, 381, 382, 455, 253, 258, 259, 430, 392, 393, 397, 398, 399, 394, 395, 454, 268, 452, 445, 446, 293, 294, 310, 309, 311, 295, 319, 320, 321, 407, 374, 269, 425, 439, 447, 383, 384, 385, 386, 387, 388, 389, 390, 391, 440, 441, 448, 450, 451, 292, 274, 289, 333, 331, 335, 330, 322, 329, 325, 290, 332, 327, 324, 323, 334, 256, 257, 409, 313, 401, 275, 276, 336, 337, 338, 339, 340, 341, 345, 346, 347, 348, 349, 351, 355, 356, 357, 358, 359, 360, 363, 423, 362, 364, 424, 278, 301, 303, 304, 305, 306, 307, 279, 353, 354, 406, 410, 413, 414, 317, 316, 318, 280, 365, 366, 281, 308, 367, 427, 428, 429, 433, 432, 456, 297, 298, 299, 312, 412, 431]
        return new Promise((resolve) => {
            let objective_details = {
                objectives: [],
                //metrics: [],
                key_results: [],
                activity_plans: [],
            }
            let params = {
                user_id: -1,
                objectives: ids.length > 1 ? ids : hardcoded_objective_ids,
            }
            window.axios.post(`api/levels/selectedobjectives`, params)
            .then((response) => {
                response.data.forEach( (objective) => {
                    //* merge all the required arrays from each objective returned
                    //objective_details.metrics = [ ...objective_details.metrics, ...objective.operational_metrics];
                    objective_details.key_results = [ ...objective_details.key_results, ...objective.key_results];
                    objective_details.activity_plans = [ ...objective_details.activity_plans, ...objective.plan_data.plans];
                });
                objective_details.objectives = response.data.map( item => {
                    let start = new Date(item.start_date);
                    let end = new Date(item.deadline)
                    let duration = differenceInDays(end, start);
                    return {
                        "id": item.id,
                        "text": item.name,
                        "start_date": start,
                        "end_date": end,
                        "duration": duration,
                        "parent": item.parent_objective_id == null ? 0 : item.parent_objective_id,
                        "progress": item.progress,
                        "open": 1,
                        "notes": item.notes ? item.notes : '',
                        "type": "project",
                        //"planned_start": item.start_date,
                        //"planned_duration": duration,
                        //"planned_end": item.deadline,
                        "item_type": 'objective'
                    }
                })
                objective_details.key_results = objective_details.key_results.map( item => {
                    return {
                        "id": 'kr-'+item.id,
                        "text": item.name,
                        "start_date": item.deadline,
                        //"start_date": item.start_date,
                        "end_date": item.deadline,
                        "duration": 1,
                        "parent": item.milestone_objective_id,
                        "progress": 25,
                        "open": 0,
                        "notes": item.notes ? item.notes : '',
                        "type": "milestone",
                        "planned_start": item.deadline,
                        //"planned_start": item.start_date,
                        "planned_duration": 2,
                        "planned_end": item.deadline,
                        "item_type": 'key_result'
                    }
                })
                objective_details.activity_plans = objective_details.activity_plans.map( item => {
                    return {
                        "id": 'plan-'+item.id,
                        "text": item.name,
                        "start_date": new Date(item.start_date),
                        "end_date": new Date(item.end_date),
                        "milestone_objective_id": item.milestone_objective_id,
                        "duration": 1,
                        "level_id": item.level_id,
                        "p_id" : item.parent_id,
                        "parent": item.milestone_objective_id,
                        "priority": item.priority,
                        "progress": item.status,
                        "open": 1,
                        "notes": item.notes ? item.notes : '',
                        "type": "task",
                        "planned_start": item.start_date,
                        "planned_duration": 2,
                        "planned_end": item.end_date,
                        "item_type": 'activity_plan',
                        "user_id": item.owner_user_id,
                        "user_name": item.owner_user_name
                    }
                })
                //*remove possible duplicates
                objective_details.objectives = uniqBy(objective_details.objectives, 'id');
                objective_details.key_results = uniqBy(objective_details.key_results, 'id');
                objective_details.activity_plans = uniqBy(objective_details.activity_plans, 'id');

                //* start task list -> objectives first
                let tasks = objective_details.objectives;
                //* using temp array to track kr's and activity plans that must be added (parent id exists as an objective)
                let temp_tasks = [];
                objective_details.key_results.forEach( key_result => {
                    //check if items linked objective exists
                    let parent_exists_index = findIndex(tasks, { id : key_result.parent})
                    if(parent_exists_index != -1){
                        temp_tasks.push(key_result)
                    }
                })
                objective_details.activity_plans.forEach( plan => {
                    //* if a plan is not a root item then update the parent id to the parent plans id
                    if(plan.p_id != 0){
                        plan.parent = `plan-${plan.p_id}`;
                    }
                    temp_tasks.push(plan)
                })

                tasks.push(...temp_tasks);
                tasks = sortBy(tasks, ['start_date']);
                commit('setGanttData', tasks);
                resolve();
            })
        })
    },*/
}

export const mutations = {
    setProjectID(state, payload) {
        state.selected_project_id = payload
    },
    setClientProjects(state, payload) {
        state.projects = payload;
    },
    setSaveKeyAction(state, payload) {
        state.saved_key_action = payload;
    },
    setGanttHidden(state, payload) {
        state.hide_gantt = payload;
    },
    setProjectsHidden(state, payload) {
        state.hide_projects = payload;
    },
    setfullscreenKanban(state, payload) {
        state.fullscreen_kanban = payload
    },
    resetGanttKanban(state){
        state.pinned_gantt_tasks = [];
        state.gantt_tasks =[];
        state.kanban_data = [];
        state.loaded_kanban_config = null;
        state.gantt_selected_objective_id = -1;
    },
    setKanbanLoadingState(state, payload){
        state.is_loading_kanban_data = payload;
    },
    setGanttData(state, payload){
        state.pinned_gantt_tasks = [];
        state.gantt_tasks = payload.map( item => {
            console.log(item.type)
            if(item.type == 'objective'){
                let start = new Date(item.start_date);
                let end = new Date(item.deadline)
                let duration = differenceInDays(end, start);
                item.duration = duration;
            }
            return item;
        });
    },
    setKanbanData(state, payload){
        console.log(JSON.parse(JSON.stringify(payload)))
        //* build list array
        let new_data_array = [];
        if(Array.isArray(payload.unassigned_plans)){
            new_data_array = payload.unassigned_plans.map( item => { item.status = 0;
            return item;});
        }
        else{
            //* if only one plan is returned, data is an object an not an array
            payload.unassigned_plans.status = 0;
            new_data_array.push(payload.unassigned_plans)
        }
        
        //state.kanban_data = payload.unassigned_plans.map( item => { item.status = 1; return item;});
        payload.lists.forEach((list)=>{
            let processed_list = list.linked_items.map( item => {
                
                return item;
            })
            processed_list.forEach( item => {
                if(list.name == 'Delayed') {
                    item.status = -1;
                } else if (list.name == 'Archived') {
                    item.status = -2;
                } else {
                    item.status = list.percentile_value;
                }
                
            })
            new_data_array.push(...processed_list);
        })

        state.kanban_data = new_data_array;
        console.log(JSON.parse(JSON.stringify(payload.lists)));
        //* set loaded config
        let config = {
            "id": payload.id,
            "client_id": payload.client_id,
            "name": payload.name,
            "default": payload.default,
            "association_id": payload.association_id,
            "association_type": payload.association_type,
            "created_at": payload.created_at,
            "updated_at": payload.updated_at,
            "lists": payload.lists,
        };
        state.loaded_kanban_config = config;
    },
    setGanttModalData(state, payload){
        state.gantt_modal_data = payload;
    },
    setGanttSelectedObjective(state, payload) {
        state.gantt_selected_item_type = payload.type;
        state.gantt_selected_objective_id = Number(payload.id);
    },
    setGanttHeight(state, payload) {
        state.gantt_height = payload;
    },
    setColumnConfig(state, payload) {
        state.gantt_column_config = payload 
    },
    setLoadingGanttAndKanban(state, payload) {
        state.gantt_kanban_loading = payload
    },
    setPinnedGanttItems(state, payload) {
        state.pinned_gantt_tasks = payload;
    },
    setPinnedGanttID(state, payload){
        state.gantt_pinned_id = payload;
    },
    setRefreshKanban(state, payload) {
        state.refresh_kanban = payload
    }

}