import Echo from "@ably/laravel-echo";
import * as Ably from "ably";
window.Ably = Ably;

class EchoManager {
  constructor() {
    this.echo = null;
    this.options = null;
  }

  init(options) {
    if (this.echo) {
      console.warn("Echo instance already initialized.");
      return;
    }
    this.options = options;
  }

  connectEcho() {
    if (this.echo) {
      console.warn("Echo instance already initialized.");
      return;
    }
    this.echo = new Echo({
      broadcaster: "ably",
      ...this.options,
      auth: {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("x_token")}`
        }
      }
    });
  }

    refreshToken() {
        if (!this.echo) {
            console.error("Echo instance not initialized.");
            return;
        }
        this.echo.connector.options.auth.headers['Authorization'] = `Bearer ${localStorage.getItem('x_token')}`;
    }


  subscribeToChannel(channelName, eventName, callback) {
    if (!this.echo) {
      console.error("Echo instance not initialized.");
      return;
    }

    this.echo.channel(channelName).listen(eventName, callback);
  }

  subscribeToPrivateChannel(channelName, eventName, callback) {
    if (!this.echo) {
      console.error("Echo instance not initialized.");
      return;
    }

    this.echo.private(channelName).listen(eventName, callback);
  }

  // Method to subscribe to a presence channel and listen to events
  subscribeToPresenceChannel(channelName, events) {
    if (!this.echo) {
      console.error("Echo is not initialized.");
      return;
    }

    const channel = this.echo.join(channelName);

    // 'here' callback provides the initial list of users present in the channel
    if (events.onHere) {
      channel.here(users => {
        events.onHere(users);
      });
    }

    // 'joining' callback is called when a new user joins the channel
    if (events.onJoining) {
      channel.joining(user => {
        events.onJoining(user);
      });
    }

    // 'leaving' callback is called when a user leaves the channel
    if (events.onLeaving) {
      channel.leaving(user => {
        events.onLeaving(user);
      });
    }

    // This example assumes you're using custom events as well
    if (events.onEvent) {
      Object.keys(events.onEvent).forEach(eventName => {
        channel.listen(eventName, data => {
          events.onEvent[eventName](data);
        });
      });
    }
  }

  subscribeToNotifications(channelName, callback) {
    this.echo.private(channelName).notification(callback);
  }

  // Method to leave/unsubscribe from any channel
  leaveChannel(channelName) {
    console.log("Leaving ECHO Channel " + channelName);
    if (!this.echo) {
      console.error("Echo is not initialized.");
      return;
    }
    // Unsubscribe from the channel
    this.echo.leave(channelName);
  }

  // Method to get the Echo socket ID
  getSocketId() {
    if (!this.echo) {
      console.error("Echo is not initialized.");
      return null;
    }
    console.log("conn key", this.echo.connector.ably.connection.key);
    return this.echo.socketId();
  }
}

// Vue Plugin
export default {
  install(Vue, { options }) {
    const echoManager = new EchoManager();
    echoManager.init(options);
    Vue.prototype.$echoManager = echoManager;
  }
};
