<script>
import appConfig from "@/app.config";
import _ from 'lodash'
import { notificationMethods, adminMethods, levelMethods, levelComputed, authMethods, peopleMethods } from "@/state/helpers";
import store from '@/state/store'
import Swal from 'sweetalert2';

export default {
    name: "app",
    page: {
        // All subcomponent titles will be injected into this template.
        titleTemplate(title) {
            title = typeof title === "function" ? title(this.$store) : title;
            return title ? `${title} | ${appConfig.title}` : appConfig.title;
        },
    },
    data: () => ({
        storage: null,
        is_validating_session: false,
        auth_timer: null,

        time: 0, // Initialize time
        timeoutID: null, // Store timeout ID for managing the timer
    }),
    mounted() {
        window.addEventListener('visibilitychange', this.handleVisibilityChange);
        this.refreshAuth();
        this.autoLogoutTimer();
        //window.addEventListener('visibilitychange', this.handleVisibilityChange);
        window.axios.interceptors.response.use(response => {
            return response; 
        }, error => {
            if (error.response.status === 401) {
                window.localStorage.setItem('x_token', null);
                //store.commit('admin/storeFailedRequest', error.config);
                //TODO - add blacklist of api calls that shouldnt trigger unauth handler eg login
                //* login with wrong password returns 401 unauth, ignore unauth handler if the login endpoint is used
                const has_url_property = error?.config?.url != undefined;
                if(has_url_property && error.config.url == 'api/auth/login'){
                    return Promise.reject(error);
                }
                else{
                    this.unauthHandler();
                }
                
            }
            else if (error.response.status === 500 || error.response.status === 403) {
                Swal.fire({
                    title: 'Something went wrong',
                    html: `Please send a screenshot of this error to <a href="">support@stratosfia.com</a>
                            <hr>
                            <span class="font-size-13">${error.response.data.message}</span>
                    `,
                })
                return Promise.reject(error.response.data.message);
            }
            else if (error.response.status === 404) {
                let url = error.response.config.url
                if(url == 'api/admin/guideline'){
                    return Promise.reject(error);
                }
                else{
                    Swal.fire({
                        title: "Server Error",
                        text: error.response.data.message,
                        icon: "warning",
                    })
                    this.$plausible.trackEvent('Backend Error Popup Shown');
                    return Promise.reject(error);
                }
            }
            return Promise.reject(error);
        });

        //* get webix table expanded states from localstorage and save in store
        let saved_treetable_state = window.localStorage.getItem('treetable_states');
        if (saved_treetable_state) {
            saved_treetable_state = JSON.parse(saved_treetable_state)
            this.$store.commit('webixTable/restoreTreeTableState', saved_treetable_state);
        }


        //* init desktop notifications 
        // if (Notification.permission !== 'granted') {
        //     Notification.requestPermission().then(permission => {
        //         if (permission === 'granted') {
        //         // All good
        //         }
        //     });
        // }
    },
    created(){
        this.autoLogoutTimer();
    },
    beforeDestroy() {
        clearInterval(this.auth_timer);
        this.cleanupAutoLogout();
        window.removeEventListener('visibilitychange', this.handleVisibilityChange);
    },
    watch: {
        company_id(){
            //* load job data
            this.loadCompanyJobs(this.company_id);
        },
        /**
         * Clear the alert message on route change
         */
        // eslint-disable-next-line no-unused-vars
        $route(to, from) {
        // clear alert on location change
        this.clearNotification();
        },
    },
    computed: {
        ...levelComputed,
    },
    methods: {
        handleVisibilityChange() {
            if (document.visibilityState === 'visible') {
                // Tab is visible again, check auth
                this.checkAuthValidation();
            }
        },
        resetTimer() {
            clearTimeout(this.timeoutID);
            this.time = 0;
            this.startTimer();
        },
        startTimer() {
            const timer = () => {
                if (this.time > 3600) {
                    this.handleValidate()
                }
                else {
                    this.time++;
                    this.timeoutID = setTimeout(timer, 1000);
                }
            };
            timer();
        },
        checkAuthValidation(){
            this.is_validating_session = true;
            this.validate().then( valid => {
                if(!valid){
                    this.showLoginOverlay();
                    // window.localStorage.removeItem('x_token');
                    // this.$router.go();
                }
                this.$nextTick(()=>{
                    this.is_validating_session = false;
                })
            })
        },
        handleValidate(){
            this.validate().then( valid => {
                if(valid){
                    this.showLoginOverlay();
                    // window.localStorage.removeItem('x_token');
                    // this.$router.go();
                }
                else{
                    this.refresh(this);
                }
            })
        },
        ...peopleMethods,
        ...authMethods,
        ...adminMethods, ...levelMethods,
        clearNotification: notificationMethods.clear,        
        refreshAuth() {
            this.auth_timer = setInterval(() => {
                if (store.getters['auth/loggedIn']) {
                    store.dispatch('auth/refresh').then((validUser) => {
                        if (validUser) {
                            this.$echoManager.refreshToken();
                        }
                    })
                }
            }, 600000)//1200000
        },
        unauthHandler: _.throttle(function(){
            this.showLoginOverlay();
        }, 400),
        autoLogoutTimer() {
            // Setup event listeners to reset the timer on activity
            document.addEventListener("mousemove", this.resetTimer);
            document.addEventListener("keypress", this.resetTimer);
            
            this.startTimer(); // Start the logout timer
        },
        cleanupAutoLogout() {
            // Clear the timer
            clearTimeout(this.timeoutID);

            // Remove event listeners to prevent memory leaks
            document.removeEventListener("mousemove", this.resetTimer);
            document.removeEventListener("keypress", this.resetTimer);
        },
        
    },
    
    
};
</script>

<template>
        <div id="app" class="app">
            <!-- <b-overlay :z-index="2000" :show="is_validating_session"> -->
                <router-view :key="$route.fullPath"></router-view>
            <!-- </b-overlay> -->
        </div>
</template>