import store from '@/state/store'
import { parseISO, format} from 'date-fns';
import Swal from 'sweetalert2'
export default {
    methods: {
        //eslint-disable-next-line
        $hasPerm(permission) {
            //if(this.isDevEnv()){
            //    return true;
            //}
            if(this.$hasUserRole('Power User')){
                return true;
            }
            let has_perm = store.getters['admin/allowedPermissions']().find(({slug}) => slug === permission);
            console.log('Checking Perm: ', permission, !!has_perm);
            return !!has_perm;
        },
        $isManagerCheck(current_user_id, selected_user_id){
            if(this.$hasUserRole('Power User')){
                return true;
            }
            
            if(current_user_id || selected_user_id == null){
                return false;
            }
            let item_owner = store.getters['levels/all_system_users']().find( user => {
                return user.id == selected_user_id;
            });
            return current_user_id == item_owner.report_to_user_id;
        },
        // 
        $hasLevelAccess(level_id){
            if(this.$hasPerm('all-structures')){
                return true;
            }
            else if(this.$hasPerm('your-structure-only')){
                // * check if level_id matches the user's assigned level_id
                if(level_id == store.getters['admin/userLinkedLevel']()){
                    return true;
                }
            }
            //* lastly check if the level_id is part of the user's allowed levels array
            let has_access = store.getters['admin/allowedLevels']().find((id) => id == level_id);
            if(!has_access){
                Swal.fire('You do not have access to this level.');
            }
            return has_access;
        },
        $hasUserRole(role_name){
            let has_role = store.getters['admin/assignedRoles']().find(({name}) => name === role_name);
            return !!has_role;
        },
        isDevEnv() {
            return location.hostname == 'localhost';
            // if (process.env.NODE_ENV == "development") {
            //     return true;
            // } else {
            //     return false;
            // }
        },
        parseDateFormat(value){
            if(!value){ return '' }
            let date = value;
            if(date !== ''){
                if(typeof date === 'string'){
                    date = parseISO(date);
                }
                return format(date, 'dd-MMM-yyyy');
            }
            else{
                return '';
            }
        },
        parseDateTimeFormat(value){
            if(!value){ return '' }
            let date = value;
            if(date !== ''){
                if(typeof date === 'string'){
                    date = parseISO(date);
                }
                return format(date, 'hh:mm');
            }
            else{
                return '';
            }
        }
    }
}