//import { buildTreeTable } from "@/utility/performance.js";
import Vue from 'vue'
import store from '@/state/store'
import _ from 'lodash';
import { format, setQuarter, endOfQuarter, addMonths, subDays, startOfYear, startOfDay, endOfYear } from 'date-fns';
export const state = {
    company_level_data:{
        vision: null,
        mission: null,
    },
    objectives_tree: [],
    goal_objectives_tree:[],

    expanded_objectives: [],
    selected_item_object: null,
    flat_objectives: [],

    performance_milestones:[],
    performance_objectives:[],

    selected_target: null,
    selected_kr_id: -1,
    selected_kr_child_id: -1,


    milestone_goals:[],
    goal_objectives:[],
    personal_objectives: [],

    selected_milestone_goal: -1,
    selected_goal_objective: -1,
    selected_personal_objective: -1,
    selected_item_depth: -1,

    parent_objectives_list: [],

    current_objective_details: null,
    objective_details_data: null,

    level_kpis: [],
    level_targets: [],
    level_plans: [],

    show_kpi_component: true,


    is_loading_graph_data:false,
    is_loading_performance_data: false,

    is_loading_milestone_objectives: false,
    is_loading_goal_objectives: false,
    is_loading_level_objectives: false,
    is_loading_kpis: false,

    objective_details_selected_tab_index: 0,

    show_vision_mission_cards: true,

    hide_strategic_plan_details: false,
    is_editing_graph_dataset: false,

    kanban_start_date: null,
    kanban_end_date: null,

    locked_target_ids: [],
    locked_kpi_ids: [],

    all_okrs_list:[],
    last_loaded_okr_id: {
        level_id: -1,
        user_id: -1,
    },
    is_viewing_okr_list: false,


    key_result_loading_id: -1,


};

export const getters = {
    //eslint-disable-next-line
    viewableRangeDate(state, state_getters, rootState){
        const is_viewing_company_level = rootState.levels.company_id == rootState.levels.current_level_id;
        let range_start, range_end = null;
        if(is_viewing_company_level){
            if(state_getters.selectedGoalObjective){
                range_start = new Date(state_getters.selectedGoalObjective.start_date);
                range_end = new Date(state_getters.selectedGoalObjective.deadline);
            }
            else if(state_getters.selectedMilestoneGoal){
                range_start = new Date(state_getters.selectedMilestoneGoal.start_date);
                range_end = new Date(state_getters.selectedMilestoneGoal.deadline);
            }
            else if(store.state.activity.selected_milestone){
                range_start = startOfYear(new Date(store.state.activity.selected_milestone.year, 0))
                range_end = endOfYear(new Date(store.state.activity.selected_milestone.year, 0))
            }
        }
        else{
            if(state_getters.selectedLevelObjective){
                range_start = new Date(state_getters.selectedLevelObjective.start_date);
                range_end = new Date(state_getters.selectedLevelObjective.deadline);
            }
            else if(store.state.activity.selected_milestone){
                range_start = startOfYear(new Date(store.state.activity.selected_milestone.year, 0))
                range_end = endOfYear(new Date(store.state.activity.selected_milestone.year, 0))
            }
        }
        if(rootState.activity.objective_viewing_quarter > 0 && rootState.activity.objective_viewing_quarter <= 4){
            return {
                range_start: setQuarter(range_start, rootState.activity.objective_viewing_quarter),
                range_end:  startOfDay(endOfQuarter(setQuarter(range_end, rootState.activity.objective_viewing_quarter)))
            }
        }
        else if(rootState.activity.objective_viewing_quarter > 4){
            switch(rootState.activity.objective_viewing_quarter){
                case 5:{
                    return {
                        range_start: setQuarter(range_start, 1),
                        range_end:  subDays(addMonths(range_start, 6), 1)
                    }
                }
                case 6:{
                    return {
                        range_start: setQuarter(range_start, 3),
                        range_end: subDays(addMonths(range_start, 12), 1)
                    }
                }
            }
        }

        //* IF no item was selected, set range to span the dates of first milestone to the last
        if(range_start == null){
            if(rootState.activity.company_milestones.length > 0){
                const start_milestone = _.minBy(rootState.activity.company_milestones, 'year');
                const end_milestone = _.maxBy(rootState.activity.company_milestones, 'year');
                range_start = startOfYear(new Date(start_milestone.year));
                range_end = startOfDay(endOfYear(new Date(end_milestone.year)));
            }
            else{
                range_start = startOfYear(new Date());
                range_end = startOfDay(endOfYear(new Date()));
            }

            
        }
        //* Now if still null, use current year as fallback
        else{
            if(store.state.activity.selected_milestone){
                range_start = startOfYear(new Date(store.state.activity.selected_milestone.year, 0))
                range_end = endOfYear(new Date(store.state.activity.selected_milestone.year, 0))
            }
            else{
                range_start = startOfYear(new Date());
                range_end = endOfYear(new Date());
            }
            
            console.log(startOfDay( endOfYear(new Date())));

        }


        
        

        return { range_start: range_start, range_end: range_end};
    },
    getSelectedTarget(state){
        return state.level_targets.find( item => {
            return state.selected_target == item.id;
        })
    },
    selectedMilestoneGoal(state) {
        return state.goal_objectives.find( item => {
            return state.selected_milestone_goal == item.id;
        })
    },
    getSelectedKR(state){
        if(!state.current_objective_details || !state.current_objective_details.key_results){
            return null;
        }
        let kr = state.current_objective_details.key_results.find( item => {
                return item.id == state.selected_kr_id;
        });
        return kr;
    },
    selectedGoalObjective(state) {
        // return state.milestone_goals.find( item => {
        //     return state.selected_goal_objective == item.id;
        // })
        if(state.selected_goal_objective == -1){
            return null;
        }
        let findItemById = function (arr, targetId) {
            for (let i = 0; i < arr.length; i++) {
              const item = arr[i];
              
              if (item.id === targetId) {
                return item; // Found the item
              }
              
              if (item.data && item.data.length > 0) {
                const found = findItemById(item.data, targetId);
                if (found) {
                  return found; // Found the item in a child
                }
              }
            }
            
            return null; // Item not found
          }
          
        let found_item = findItemById(state.milestone_goals, state.selected_goal_objective);
        console.log('Found Item', found_item);
        return found_item;
    },
    selectedLevelObjective(state){
        if(state.selected_goal_objective == -1){
            return null;
        }
        let findItemById = function (arr, targetId) {
            for (let i = 0; i < arr.length; i++) {
              const item = arr[i];
              
              if (item.id === targetId) {
                return item; // Found the item
              }
              
              if (item.data && item.data.length > 0) {
                const found = findItemById(item.data, targetId);
                if (found) {
                  return found; // Found the item in a child
                }
              }
            }
            
            return null; // Item not found
          }
          
        let found_item = findItemById(state.goal_objectives_tree, state.selected_goal_objective);
        console.log('Found Item', found_item);
        return found_item;
    },
    levelObjectives(state){
        return state.performance_objectives.filter( item => item.objective_type == 'level')
    }
}

export const mutations = {
    setIsViewingObjectiveList(state, value){
        state.is_viewing_okr_list = value;
    },
    setKeyResultLoadingID(state, id){
        state.key_result_loading_id = id;
    },
    setLastLoadedOkrParams(state, payload){
        //* track last used parameters when fetching okr data
        const level = payload.level_id != undefined ? payload.level_id : -1;
        const user = payload.user_id != undefined ? payload.user_id : -1;

        Vue.set(state.last_loaded_okr_id, 'level_id', level);
        Vue.set(state.last_loaded_okr_id, 'user_id', user);
    },
    setOkrData(state, payload){
        state.all_okrs_list = payload;
    },
    updateGraphData(state, payload){
        const { index } = payload;
        let current_obj = _.cloneDeep(state.level_targets[index]);
        Vue.set(state.level_targets, index, current_obj);
    },
    updateSingleObjective(state, payload){
        const { index, data } = payload;
        let current_obj = data;
        //* mutate array in reactive way
        Vue.set(state.all_okrs_list, index, current_obj);
    },
    //eslint-disable-next-line
    updateGraphDataset(state, payload){
        //eslint-disable-next-line
        const { index, data, type, target_index } = payload;
        //* Init item that will be mutated and updated
        let current_obj = null;

        if(type == 'kpi'){
            //* Handle kpi update (child of target)
            // let current_obj = _.cloneDeep(state.level_targets[target_index]);
            // current_obj.kpis[]
            console.log('GRAPH REFRESH EVENT', payload);
        }
        else{
            //* Handle target update
            current_obj = _.cloneDeep(state.level_targets[index]);
            //* update tracking array from new data
            current_obj.tracking = data.tracking;
            current_obj.kpis = data.kpis;
        }
        

        //* mutate array in reactive way
        Vue.set(state.level_targets, index, current_obj);
    },
    setLevelPlans(state, value){
        state.level_plans = value;
    },
    showStrategicPlanDetails(state, value){
        state.hide_strategic_plan_details = value;
    },
    setKpisLoadingState(state, value){
        state.is_loading_kpis = value;
    },
    setObjectiveDetailsTabIndex(state, index){
        state.objective_details_selected_tab_index = index;
    },
    setSelectedKR(state, id){
        state.selected_kr_id = id;
    },
    setSelectedKRChild(state, id){
        state.selected_kr_child_id = id;
    },
    setSelectedTarget(state, id) {
        state.selected_target = id;
    },
    expandObjective(state, newValue) {
        if(newValue == null){
            state.selected_item_object = [];
            state.expanded_objectives = [];
        }
        else{
            state.expanded_objectives.push(newValue);
        }
        
    },
    setObjectivesTree(state, newValue) {
        state.goal_objectives_tree = newValue;
    },
    hideChildren(state, index) {
        let new_array = [];
        state.expanded_objectives.forEach( (item, list_index) => {
            if(list_index < index){
                new_array.push(item);
            }
        })
        state.expanded_objectives = new_array;
    },
    setFlatObjectives(state, data){
        state.flat_objectives = data;
    },
    setSelection(state, obj) {
        state.selected_item_object = obj;
    },

    setLevelPerformanceData(state, data){
        state.performance_objectives = data.objectives;
    },
    setMilestonePerformanceData(state, data){
        console.log(state, data);
    },
    setMilestoneGoals(state, data){
        state.milestone_goals = data;
    },
    setGoalObjectives(state, data){
        state.goal_objectives = data;
    },
    setPersonalObjectives(state, data){
        state.personal_objectives = data;
    },
    setVisionMission(state, data){
        state.company_level_data = data;
    },
    setParentObjectives(state, data){
        state.parent_objectives_list = data;
    },

    setSelectedMilestoneGoal(state, id){
        state.selected_milestone_goal = id
    },
    setSelectedGoalObjective(state, id){
        state.selected_goal_objective = id
    },
    setPersonalObjectiveId(state,id) {
        state.selected_personal_objective = id
    },
    setSelectedItemDepth(state, id){
        state.selected_item_depth = id
    },
    setCurrentObjectiveDetails(state, objective_object){
        state.current_objective_details = objective_object;
    },
    setObjectiveData(state, objective_data){
        state.objective_details_data = objective_data;
    },

    setLevelTargets(state, array){
        state.level_targets = array;
    },
    setLevelKpis(state, array){
        state.level_kpis = array;
    },
    setIsEditingDataset(state, value){
        state.is_editing_graph_dataset = value
    },
    setIsEditingDatasetKPI(state, value){
        state.is_editing_kpi_graph_dataset = value
    },

    setLoadingGraphData(state, value){
        state.is_loading_graph_data = value;
    },
    setLoadingMilestoneData(state, value){
        state.is_loading_milestone_objectives = value;
    },
    setShowKpis(state, value){
        state.show_kpi_component = value;
    },
    setVisionMissionCardVisibility(state, value){
        state.show_vision_mission_cards = value
    },
    setStartDate(state, value) {
        state.kanban_start_date = value
    },
    setEndDate(state, value) {
        state.kanban_end_date = value
    },
    updateLockedGraphsArray(state, payload){
        if(payload.type == 'target_graph'){
            state.locked_target_ids = payload.updated_array;
        }
        else{
            state.locked_kpi_ids = payload.updated_array;
        }
    },
    //eslint-disable-next-line
    resetData(state, value){
        state.company_level_data ={
            vision: null,
            mission: null,
        };
        state.objectives_tree = [];
        state.goal_objectives_tree = [];
    
        state.expanded_objectives = [];
        state.selected_item_object = null;
        state.flat_objectives = [];
        state.performance_milestones = [];
        state.performance_objectives = [];
        state.selected_target = null;
        state.selected_kr_id = -1;
        state.selected_kr_child_id = -1;
        state.milestone_goals = [];
        state.goal_objectives = [];
        state.selected_milestone_goal = -1;
        state.selected_goal_objective = -1;
        state.parent_objectives_list = [];
        state.current_objective_details = null;
        state.objective_details_data = null;
        state.level_kpis = [];
        state.level_targets = [];
        state.level_plans = [];
        state.show_kpi_component = true;
        state.is_loading_graph_data = false;
        state.is_loading_performance_data = false;
        state.is_loading_milestone_objectives = false;
        state.is_loading_goal_objectives = false;
        state.is_loading_level_objectives = false;
        state.is_loading_kpis = false;
        state.objective_details_selected_tab_index = 0;
        state.show_vision_mission_cards = true;
        state.hide_strategic_plan_details = false;
        state.is_editing_graph_dataset = false;
        state.kanban_start_date = null;
        state.kanban_end_date = null;
    }
};

export const actions = {
    clearAll({commit}){
        return new Promise((resolve) => {
            commit('resetData', null);
            resolve();
        })
    },
    setIsViewingObjectiveList({commit}, value){
        commit('setIsViewingObjectiveList', value);
    },
    setIsEditingDataset({commit}, value){
        commit('setIsEditingDataset', value);
    },
    setIsEditingDatasetKPI({commit}, value){
        commit('setIsEditingDatasetKPI', value);
    },
    showStrategicPlanDetails({commit}, value){
        commit('showStrategicPlanDetails', value);
    },
    setObjectiveDetailsTabIndex({commit}, new_index){
        commit('setObjectiveDetailsTabIndex', new_index);
    },
    setSelectedKR({commit}, id){
        commit('setSelectedKR', id);
    },
    setSelectedKRChild({commit}, id){
        commit('setSelectedKRChild', id);
    },
    setSelectedTarget({commit}, id){
        commit('setSelectedTarget', id);
    },
    setSelectedItemDepth({commit}, id){
        commit('setSelectedItemDepth', id);
    },
    setKeyResultLoadingID({commit}, id){
        commit('setKeyResultLoadingID', id)
    },
    fetchSingleObjective({commit}, payload){
        let request_payload = payload;
        if(request_payload.user_id == -1){
            delete request_payload.user_id;
        }
        return new Promise((resolve, reject) => {
            window.axios.get('api/performance/data', { params : payload }).then(response =>  {
                //* get 'goal' type objectives
                let response_data = response.data;
                const update_params = {
                    index: state.all_okrs_list.findIndex( e => e.id == response_data.id),
                    data: response_data,
                }
                commit('updateSingleObjective', update_params);
                resolve();
            }).catch(() => {
                reject();
            });
        });
    },
    fetchOkrData({commit}, payload){

        let request_payload = payload;
        if(request_payload.user_id == -1){
            delete request_payload.user_id;
        }

        return new Promise((resolve, reject) => {
            window.axios.get('api/performance/okr_data', { params : payload }).then(response =>  {
                //* get 'goal' type objectives
                let response_data = response.data.filter((ele) => ele.objective_type == 'goal');
                response_data = _.uniqBy(response_data, 'id');
                
                commit('setLastLoadedOkrParams', payload);
                commit('setOkrData', response_data);
                resolve();
            }).catch(() => {
                reject();
            });
        });
    },
    fetchGraphData({commit, state}, payload){
        return new Promise((resolve, reject) => {
            window.axios.get('api/performance/graph_data', { params : payload }).then(response =>  {
                // get index of item that must be mutated and pass it to the mutation hook
                let response_data = response.data;
                const update_params = {
                    index: state.level_targets.findIndex( e => e.id == response_data.id),
                    data: response_data,
                }
                commit('updateGraphData', update_params);
                resolve();
            }).catch(() => {
                reject();
            });
        });
    },
    //eslint-disable-next-line
    fetchGraphDataset({commit, state, getters}, payload){
        console.log("GRAPH stuff",payload)
        return new Promise((resolve, reject) => {
            if(payload.graph_type == 'kpi'){
                resolve();
            }
            window.axios.get('api/performance/graph_data', { params : payload }).then(response =>  {
                // get index of item that must be mutated and pass it to the mutation hook
                let response_data = response.data;
                const update_params = {
                    index: -1, // need to get index of graph item
                    data: response_data,
                    type: this.graph_type,
                }

                if(this.graph_type == 'kpi'){
                    //* get the target index and kpi index of the data that needs to be updated
                    for(let i = 0; i < state.level_targets.length; i++){
                        let kpi_index = state.level_targets.findIndex(( e => e.id == response_data.id));
                        if(kpi_index != -1){
                            //* found kpi, now set optional extra field on the update_params
                            update_params['target_index'] = i;
                            update_params.index = kpi_index;
                            break;
                        }
                    }
                }
                else{
                    //* get target index from level_targets array
                    update_params.index = state.level_targets.findIndex( e => e.id == response_data.id)

                }


                console.log('UPDATE PARAMS', payload, update_params);

                //* only try to update item if it exists in the current data store (eg index not -1)
                if(update_params.index > -1){
                    commit('updateGraphDataset', update_params);
                }
                
                resolve();
            }).catch(() => {
                reject();
            });
        });
    },
    //eslint-disable-next-line
    setGraphRecalculationState({commit, dispatch, state}, data){
        const { action } = data;

        //* get array of grapgs that are loading new data (target graphs or kpis)
        let locked_ids = [];
        if(data.graph_type == 'target_graph' ){
            locked_ids = _.cloneDeep(state.locked_target_ids);
        }
        else{
            locked_ids = _.cloneDeep(state.locked_kpi_ids);
        }
        //* Check if current graph is in the locked array
        let index = locked_ids.findIndex( id => id == data.graph);

        switch(action){
            case 'recalc_complete':{
                //* if graph index != -1, remove it from locked array
                if(index != -1){
                    locked_ids.splice(index, 1);
                    let params = {
                        graph_id: data.graph,
                        graph_type: data.graph_type == 'kpi' ? data.graph_type : 'target',
                        start_date: '2024-01-01',
                        end_date: '2024-12-31',
                    }
                    if(params.graph_type == 'kpi'){
                        console.log('TRYING TO LOAD');
                        console.log(JSON.stringify(params))
                        return;
                    }
                    dispatch('fetchGraphDataset', params);
                }
                break;
            }
            case 'recalc_in_progress':{
                //* if graph index is -1, add it to array
                if(index == -1){
                    locked_ids.push(data.graph);
                    break;
                }
            }
        }

        let commit_payload = {
            updated_array: locked_ids,
            type: data.graph_type
        }
        commit('updateLockedGraphsArray', commit_payload)
    },
    // eslint-disable-next-line
    saveTargets({}, payload){
        return new Promise( (resolve, reject) => {
            window.axios.post('api/performance/save_targets', payload)
            .then(()=>{
                resolve();
            })
            .catch(() => {
                reject();
            })
        })
    },
    // eslint-disable-next-line
    saveTargetTracking({}, payload){
        return new Promise( (resolve, reject) => {
            window.axios.post('api/performance/save_target_tracking', payload)
            .then(()=>{
                resolve();
            })
            .catch(() => {
                reject();
            })
        })
    },
    // eslint-disable-next-line
    saveKeyPerformanceTracking({}, payload){
        return new Promise( (resolve, reject) => {
            window.axios.post('api/performance/save_kpi_tracking', payload)
            .then(()=>{
                resolve();
            })
            .catch(() => {
                reject();
            })
        })
    },

    saveKpis({commit}, payload){
        return new Promise( (resolve, reject) => {
            commit('setKpisLoadingState', true);
            window.axios.post('api/performance/save_kpis', payload)
            .then(()=>{
                commit('setKpisLoadingState', false);
                resolve();
            })
            .catch(() => {
                commit('setKpisLoadingState', false);
                reject();
            })
        })
    },
    // eslint-disable-next-line
    deleteTarget({}, id){
        return new Promise( (resolve, reject) => {
            window.axios.post('api/performance/delete_target', {target_id: id})
            .then(()=>{
                resolve();
            })
            .catch(() => {
                reject();
            })
        })
    },
    // eslint-disable-next-line
    deleteKpi({}, params){
        return new Promise( (resolve, reject) => {
            window.axios.post('api/performance/delete_kpi', params)
            .then(()=>{
                resolve();
            })
            .catch(() => {
                reject();
            })
        })
    },
    // eslint-disable-next-line
    deleteKR({}, id){
        return new Promise( (resolve, reject) => {
            window.axios.post('api/performance/delete_keyresult', {key_result_id: id})
            .then(()=>{
                resolve();
            })
            .catch(() => {
                reject();
            })
        })
    },
    // eslint-disable-next-line
    saveObjectives({}, payload){
        return new Promise( (resolve, reject) => {
            window.axios.post('api/performance/save_objectives', payload)
            .then(()=>{
                resolve();
            })
            .catch(() => {
                reject();
            })
        })
    },
    setShowKpis({commit}, bool_value){
        commit('setShowKpis', bool_value);
    },
    //performance/
    // eslint-disable-next-line
    deleteObjectives({}, payload){
        return new Promise( (resolve, reject) => {
            window.axios.post('api/performance/delete_objective', {ids: payload, force: false})
            .then((response)=>{
                resolve(response);
            })
            .catch((response) => {
                reject(response);
            })
        })
    },
    // eslint-disable-next-line
    forceDeleteObjectives({}, payload){
        return new Promise((resolve, reject) => {
            window.axios.post('api/performance/delete_objective', {ids: payload, force: true})
            .then((result)=>{
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            })
        })
    },
    //eslint-disable-next-line
    saveKeyResultData({}, payload){
        console.log('PAYLOAD', payload)
        return new Promise( (resolve, reject) => {
            window.axios.post(`api/performance/save_keyresults`, payload)
            .then(()=>{
                resolve();
            })
            .catch(()=>{
                reject();
            })
        })
    },
    setCurrentObjectiveDetails({commit}, obj){
        commit('setCurrentObjectiveDetails', obj)

    },
    //eslint-disable-next-line
    loadPerformanceScreenData({commit, dispatch}, {type, params}){
        if(params.user_id && params.user_id == -1){
            delete params.user_id;
        }
        let dates = store.getters['performance/objectives/viewableRangeDate'];
        // if(store.getters['activity/getViewingQuarter'] && dates.start){
            // params['start_date'] = format(dates.start, 'yyyy-MM-dd');
            // params['end_date'] = format(subDays(dates.end, 1), 'yyyy-MM-dd');       
        // }
        params['start_date'] = format(dates.range_start, 'yyyy-MM-dd');
        params['end_date'] = format(dates.range_end, 'yyyy-MM-dd');

        //let full_dates = store.getters['performance/objectives/viewableRangeDate'];
        //! TODO - Remove from kanban store because the start/end dates are available  in the getter above
        commit('setStartDate', format(dates.range_start, 'yyyy-MM-dd'));
        commit('setEndDate', format(dates.range_end, 'yyyy-MM-dd'));
        switch(type){
            case 'level':{
                commit('setLoadingMilestoneData', true);
            }
        }
        return new Promise( (resolve, reject) => {
            window.axios.get(`api/performance/data`, {params: params})
            .then(( response )=>{
                switch(type){
                    case 'level':{
                        //let found = _.findIndex(response.data.objectives, { id: 431});
                        //commit('setLevelPerformanceData', response.data);
                        let milestone_goals = _.filter(response.data.objectives, { objective_type : 'goal' });
                        let personal_objectives = _.filter(response.data.objectives, { objective_type : 'personal'});                       
                        let level_objectives = _.filter(response.data.objectives, { objective_type : 'level'});
                        let milestone_objectives = _.filter(response.data.objectives, { objective_type : 'milestone'});
                        milestone_objectives.forEach( item => {
                            delete item.data;
                        })
                        commit('setParentObjectives', response.data.parent_objectives);
                        dispatch('buildObjectivesTree', {
                            type: 'level',
                            flat_array: level_objectives
                        });
                        dispatch('buildObjectivesTree', {
                            type: 'goal',
                            flat_array: milestone_goals
                        });
                        //commit('setMilestoneGoals', milestone_goals);
                        commit('setGoalObjectives', milestone_objectives)
                        commit('setPersonalObjectives', personal_objectives)
                        commit('setVisionMission', {
                            vision: response.data.vision,
                            mission: response.data.mission,
                        })
                        break;
                    }
                    case 'milestone':{
                        commit('setMilestonePerformanceData', response.data);
                        break;
                    }
                    case 'objective_details':{
                        commit('setObjectiveData', response.data);
                        break;
                    }
                    case 'objective':{ 
                        commit('setCurrentObjectiveDetails', response.data)
                        //commit('setObjectiveData', response.data);
                        break;
                    }
                    case 'plans':{
                        commit('setLevelPlans', response.data.level_plans)
                        break;
                    }
                    case 'targets' :{
                        commit('setLoadingGraphData', true);
                        if(response.data.targets != undefined){
                            commit('setLevelTargets',response.data.targets);
                            commit('setLoadingGraphData', false);
                        }
                        if(response.data.kpis != undefined){
                            commit('setLevelKpis',response.data.kpis);
                            commit('setLoadingGraphData', false);
                        }
                        break;
                    }
                    default :{
                        break;
                    }
                }
                resolve();
            })
            .catch(()=>{
                reject();
            })
        })
    },
    //eslint-disable-next-line no-empty-pattern
    expandObjectives({commit}, value){
        return new Promise( resolve => {
            commit('expandObjective', value);
            resolve();
        })
    },
    hideChildren({commit}, index){
        commit('hideChildren', index);
    },
    setSelection({commit}, obj){
        commit('setSelection', obj);
    },

    //* create nested array of objectives
    async buildObjectivesTree({commit}, { type, flat_array }){
        console.log('DEBUG', type, flat_array)
        const map = {};
        const nestedArray = [];
        let pre_processed_array = [];
        return new Promise( (resolve) => {
            if(flat_array.length == 0){
                resolve();
            }
            //* get unique by id
            pre_processed_array = _.uniqBy(flat_array, 'id');
            //* set to correct order
            //pre_processed_array = _.orderBy(pre_processed_array, ['item_order']);
            pre_processed_array = pre_processed_array.map((item) => {
                let new_item = {
                    parent_milestone_name: item.parent_milestone_name, 
                    parent_objective_name: item.parent_objective_name,
                    level_milestone_id: item.level_milestone_id,
                    parent_objective_id: item.parent_objective_id,
                    level_id: item.level_id,
                    id: item.id,
                    progress: item.progress,
                    year: item.year,
                    quarter: item.quarter,
                    month: item.month,
                    name: item.name,
                    parent_id: item.parent_objective_id,
                    start_date: item.start_date,
                    deadline: item.deadline,
                    owner_name: item.owner_name,
                    item_order: item.item_order, // no longer needed
                    owner_user_id: item.owner_user_id,
                    status: item.status,
                    change: item.change,
                    data: [], // array of children
                    type: "folder",
                    notes: item.notes,
                    category: item.category
                }
                map[item.id] = { ...new_item, };
                return new_item;
            });
            pre_processed_array.forEach((item) => {
                if (item.parent_id !== null) {
                    if (map[item.parent_id]) {
                        map[item.parent_id].data.push(map[item.id]);
                    }
                    else {
                        nestedArray.push(map[item.id]);
                    }
                }
                else {
                    nestedArray.push(map[item.id]);
                }
            });
            if(type == 'goal'){
                commit('setMilestoneGoals', nestedArray);
            }
            else{
                // level
                commit('setObjectivesTree', nestedArray);
                commit('setLoadingMilestoneData', false);
            }
            
            resolve();
        })
    },

    // selection methods
    selectMilestoneGoal({commit}, id){
        commit('setSelectedMilestoneGoal', id);
    },
    selectGoalObjective({commit}, id){
        commit('setSelectedGoalObjective', id);
    },
    selectPersonalObjective({commit}, id){
        commit('setPersonalObjectiveId', id);
    },
    setVisionMissionCardVisibility({commit}, value){
        commit('setVisionMissionCardVisibility', value);
    },
    
};
