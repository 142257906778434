
export const state = {
    menu: [],
    menu_loading: true,
}

export const actions = {
    updateMenu({commit}, data){
        commit('setMenu', data);
    },
    setLoading({commit}, data){
        commit('loading', data);
    },
    clearAll({commit}){
        return new Promise((resolve) => {
            commit('resetStateData', null);
            resolve();
        })
    },
}

export const mutations = {
    setMenu(state, payload){
        state.menu = payload;
    },
    loading(state, payload){
        state.menu_loading = payload;
    },
    //eslint-disable-next-line
    resetStateData(state, payload){
        state.menu = [];
        state.menu_loading = true;
    },
}