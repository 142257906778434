export const state = {
  show_kpi_toggle: false,
  show_expiry_timer: false,
  is_editing_kpi_dataset: false,
  is_editing_metric_dataset: false,
  is_editing_kr_dataset: false,
  is_reloading_graph_data: false,
}

export const actions = {
  setGraphToggleState({ commit }, toggled_state) {
    commit('setToggleState', toggled_state);
  },
  setGraphEditState({ commit }, edit_state) {
    commit('setEditState', edit_state);
  },
  setExpiryTimerState({ commit }) {
    commit('setTimerState');
  },

  setGraphLoadingState({commit}, state){
    commit('setGraphLoadingState', state)
}
}

export const mutations = {
  setGraphLoadingState(state, payload){
    state.is_reloading_graph_data = payload;
  },
  setTimerState() {
    state.show_expiry_timer = true;
  },
  setToggleState(state, toggle_payload) {
    
    switch(toggle_payload.type){
      case 'kpi':{
        state.show_kpi_toggle = toggle_payload.state;
        if(toggle_payload.state == false){
          state.is_editing_kpi_dataset = false;
        }
        break;
      }
    }
  },
  setEditState(state, edit_state){
    switch(edit_state.type){
      case 'kpi':{
        state.is_editing_kpi_dataset = edit_state.state;
        break;
      }
      case 'metric':{
        state.is_editing_metric_dataset = edit_state.state;
        break;
      }
      case 'kr':{
        state.is_editing_kr_dataset = edit_state.state;
        break;
      }
    }
  }

}


