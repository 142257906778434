

export const state = {
    activity_data: null,
    selected_milestone: null,
    selected_objective: null,
    objective_data: null,
    selected_key_result: null,


    ownerDropdown:null,
}

export const getters = {
}

export const actions = {
    loadActivityData({commit}, level_id){
        return new Promise((resolve, reject) => {
            window.axios.get('api/levels/level/' + level_id + '/')
            .then((response) => {
                commit('setActivityData', response.data);
                resolve();
            })
            .catch((error) => {
                console.log('Error:', error);
                reject();
            })
        })
    },
    loadObjectiveData({commit}, objective_id){
        return new Promise((resolve, reject) => {
            window.axios.get('api/levels/objective/' + objective_id)
            .then((response) => {
                commit('setObjectiveData', response.data);
                resolve();
            })
            .catch((error) => {
                console.log('Error:', error);
                reject();
            })
        })
    },
    //eslint-disable-next-line no-empty-pattern
    saveMilestone({}, params){
        return new Promise((resolve, reject) => {
            window.axios.post('api/levels/savemilestone', params)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Milestone error', error);
                reject(error);
            })
        })
    },
    //eslint-disable-next-line no-empty-pattern
    saveObjective({}, params){
        return new Promise((resolve, reject) => {
            window.axios.post('api/levels/saveobjective', params)
            .then((response)=>{
                resolve(response.data);
            })
            .catch(() => {
                reject();
            })
        })
    },
    //eslint-disable-next-line no-empty-pattern
    saveKeyResult({}, params){
        return new Promise((resolve, reject) => {
            window.axios.post('api/levels/savekeyresult', params)
            .then(()=>{
                resolve();
            })
            .catch(() => {
                reject();
            })
        })
    },




    setSelectedMilestone({commit}, milestone){
        return new Promise(resolve => {
            commit('setMilestone', milestone);
            resolve();
        })
    },
    setSelectedObjective({commit}, objective){
        return new Promise(resolve => {
            commit('setObjective', objective);
            resolve();
        })
    },
    setSelectedKeyResult({commit}, objective){
        return new Promise(resolve => {
            commit('setKeyResult', objective);
            resolve();
        })
    },


    setOwnerDropdownAction({commit},payload){
        commit('setOwnerDropdown',payload);
    },

}

export const mutations = {
    setOwnerDropdown(state, payload){
        state.ownerDropdown = payload;
    },


    setActivityData(state, payload){
        state.activity_data = payload;
    },
    setMilestone(state, payload){
        state.selected_milestone = payload;
    },
    setObjective(state, payload){
        state.selected_objective = payload;
    },
    setKeyResult(state, payload){
        state.selected_key_result = payload;
    },
    setObjectiveData(state, payload){
        state.objective_data = payload;
    },
}
