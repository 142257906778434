
export const state = {
    grouped: false,
    is_left_menu_collapsed: false,
};

export const mutations = {
    setGroupingMode(state, newValue) {
        state.grouped = newValue
    },
    setMenuState(){
        state.is_left_menu_collapsed = !state.is_left_menu_collapsed;
    }
};

export const actions = {
    setGrouping({ commit }, newValue) {
        commit('setGroupingMode', newValue)
    },
    setLeftMenuState({commit}){
        commit('setMenuState');
    }
};
