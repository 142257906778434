/**
 * @Author: Calvin Green <calvin>
 * @Date:   2021-12-03T10:24:59+02:00
 * @Email:  calvin@diversitude.com
 * @Last modified by:   calvin
 * @Last modified time: 2021-12-15T19:59:00+02:00
 * @Copyright: Diversitude 2021
 */



import _ from 'lodash';


export const state = {
    levels: null,               //* all company structures (left tree menu)
    selected_level: null,       //* active level id
    current_level_id: null,
    company_id: null,         //* the top top top parent level id of the currently selected level
    level_users: null,
    menu_users: null,
    is_loading_menu_users: false,
    org_chart_levels: null,
    dropDownUsers: [],
    selected_user_id: null,
    selected_user_job_key: null,
    selected_user_object: null,

    all_users:[],

    allow_tree_selection: false,
    assign_user_levels_mode: false,

    //levels_tree_state (opened items)
    levels_tree_state: null,

    reposition:{
        target: null,
        source: null,
    },

    level_teams: null,
    selected_team_id: -1,

}

export const getters = {

    findLevelUserId: (state) => (name) => {
        let result = state.level_users.find(item => {

            return item.id == name || item.name == name
        }
            )

        return result
    },
    getHierarchy: (state) => (levelId) => {
        //! can delete
        let final = ''

        let result = state.levels.find(item => {

            return item.id == levelId})

        let parent = state.levels.find(item => {

            return item.id == result.parent_id})

        if(parent && !parent.parent_id) final += `${parent.name} > ${result.name}`
        else if(parent) final += `... > ${parent.name} > ${result.name}`
        else final += result.name


        return final
    },
    all_system_users: (state) => () => {
        return state.all_users;
    }

}


export const actions = {
    clearAll({commit}){
        return new Promise((resolve) => {
            commit('resetStateData', null);
            resolve();
        })
    },
    setUserOnlineState({ commit, state}, payload){
        let user_job_key = null;
        let user_index = -1
        for(let key in state.menu_users){
            let index = state.menu_users[key].findIndex( item => {
                return item.id == payload.user_id;
            })
            if(index != -1){
                user_job_key = key;
                    user_index = index;
            }
            //found user, stop searching
            if(user_job_key != null){
                break;
            }
        }

        if(user_index != -1){
            let updated_users = _.cloneDeep(state.menu_users);
            updated_users[user_job_key][user_index]['is_online'] = payload.state;
            commit('setMenuUsers', updated_users);
        }
    },
    setMessageIconForUser({ commit, state }, user_id){
        let user_job_key = null;
        let user_index = -1
        for(let key in state.menu_users){
            let index = state.menu_users[key].findIndex( item => {
                return item.id == user_id;
            })
            if(index != -1){
                user_job_key = key;
                    user_index = index;
            }
            //found user, stop searching
            if(user_job_key != null){
                break;
            }
        }

        if(user_index != -1){
            let updated_users = _.cloneDeep(state.menu_users);
            updated_users[user_job_key][user_index]['has_message'] = true;
            commit('setMenuUsers', updated_users);
        }
    },
    // eslint-disable-next-line
    setEmployeeArchiveState({}, user_object){
        return new Promise((resolve, reject) => {
            //use a stripped down version of the updateemployee endpoint -- only pass in id and active=false
            window.axios.post('api/people/updateemployee', user_object)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                reject(error);
            })
        })
    },
     // eslint-disable-next-line
    sendDuplicationRequest({}, data){
        return new Promise((resolve, reject)=>{
            window.axios.post('api/levels/duplicategraphs', data)
            .then(()=>{
                resolve();
            })
            .catch((error)=>{
                reject(error);
            })
        })
    },
    selectLevelTeam({commit}, id){
        commit('setSelectedTeam', id);
    },
    loadLevelTeams({commit}, params){
        return new Promise((resolve, reject)=>{
            window.axios.get('api/levels/teams', {params: params})
            .then((response)=>{
                commit('setLevelTeams', response.data);
                resolve();
            })
            .catch(()=>{
                reject();
            })
        })
    },
    loadLevels({commit}){
        return new Promise((resolve, reject)=>{
            window.axios.get('api/admin/levels')
            .then((response)=>{
                commit('setLevels', response.data);
                resolve();
            })
            .catch(()=>{
                reject();
            })
        })
    },
    //eslint-disable-next-line no-unused-vars
    selectLevel({commit, state}, level){
        return new Promise((resolve)=>{
            //* disabled - load users after loading the current level in the level-menu component
            let company_id = null;
            if(level.parent_id == null){
                //* company levels have parent_id == null
                company_id = level.id
            }
            else{
                let current_level = level;
                while(current_level.parent_id != null){
                    //console.log('WHILE LOG 10')
                    let item = state.levels.find( lvl => lvl.id == current_level.parent_id);
                    current_level = item;
                }
                company_id = current_level.id;
            }
            
            commit('setCompanyLevelId', company_id);
            commit('setSelectedLevel', level);

            window.localStorage.setItem('level_id', level.id);
            resolve();
        })
    },
    updateSelectedLevel({commit}, level_id){
        return new Promise((resolve, reject)=>{
            window.axios.get('api/admin/levels')
            .then((response)=>{
                let levels = response.data;
                let index = _.findIndex(levels, {id: level_id});
                if(index != -1){
                    commit('setUpdatedLevel', levels[index]);
                }
                resolve();
            })
            .catch(()=>{
                reject();
            })
        })
    },
    loadLevelUsers({commit}, level_id){
        return new Promise((resolve, reject)=>{
            window.axios.get('api/admin/levelusers/' + level_id)
            .then((response) => {
                commit('setLevelUsers', response.data);
                commit('setDropDownUsers', response.data)
                resolve();
            })
            .catch(()=>{
                reject();
            })
        })
    },
    //eslint-disable-next-line
    loadMenuUsers({commit}, level_id){
        commit('setLoadingState', true);
        return new Promise((resolve, reject)=>{
            window.axios.get('api/admin/menuusers/' + level_id)
            .then((response) => {
                commit('setMenuUsers', response.data);
                commit('setLoadingState', false);
                resolve();
            })
            .catch(()=>{
                commit('setLoadingState', false);
                reject();
            })
        })
    },
    //eslint-disable-next-line
    loadAllUsers({commit}){
        return new Promise((resolve, reject)=>{
            window.axios.get('api/admin/levelusers/-1')
            .then((response) => {
                commit('setAllUsers', response.data);
                resolve();
            })
            .catch(()=>{
                reject();
            })
        })
    },
    saveLevels({commit}, levels){
        commit('setOrgChartLevels', levels);
    },


    //*user tree selection
    setSelectedUser({commit}, user_payload){
        commit('setSelectedUserID', user_payload);
    },
    

    setLevelAccessSelectionFlag({commit}, value){
        commit('setLevelAccessSelectionFlag', value);
    },
    setLevelAssignFlag({commit}, value){
        commit('setLevelAssignFlag', value);
    },

    saveLevelsTreeState({commit}, payload){
        commit('setLevelsTreeState', payload);
    },

    setRepositionValue({commit}, payload){
        commit('setRepositionValue', payload)
    }
}

export const mutations = {
    setLevelsTreeState(state, payload){
        state.levels_tree_state = payload;
    },
    setAllUsers(state, payload){
        state.all_users = payload;
    },
    setLevels(state, payload){
        state.levels = payload;
    },
    setUpdatedLevel(state, payload){
        state.selected_level = payload;
    },
    setSelectedLevel(state, payload){
        let level = payload;
        if(Number(level.id !== state.current_level_id)){
            state.current_level_id = level.id;
            state.selected_level = level;
        }
        
    },
    setMenuUsers(state, payload){
        state.menu_users = payload;
    },
    setLoadingState(state, payload){
        state.is_loading_menu_users = payload;
    },
    setLevelUsers(state, payload){
        state.level_users = payload;
    },
    setDropDownUsers(state, payload) {
        const results = payload?.map((item) => {
            return item.name;
        });
        state.dropDownUsers = results;
    },
    setOrgChartLevels(state, payload){
        state.org_chart_levels = payload;
    },
    setSelectedUserID(state, payload){
        if(payload == null){
            window.localStorage.removeItem('last_selected_user_id');
            state.selected_user_id = null;
            state.selected_user_job_key = null;
        }
        else{
            window.localStorage.setItem('last_selected_user_id', payload.id);
            state.selected_user_id = payload.id;
            state.selected_user_job_key = payload.job_key;

            let index = _.findIndex(state.menu_users[payload.job_key], {id:Number(payload.id)});
            if(index != -1){
                state.selected_user_object = state.menu_users[payload.job_key][index];
            }
            else{
                state.selected_user_object = null;
            }
        }
        
    },
    setLevelTeams(state, payload){
        state.level_teams = payload;
    },
    setSelectedTeam(state, id){
        state.selected_team_id = id;
    },
    setCompanyLevelId(state, id){
        state.company_id = id;
    },

    setLevelAccessSelectionFlag(state, value){
        state.allow_tree_selection = value;
    },
    setLevelAssignFlag(state, value){
        state.assign_user_levels_mode = value;
        state.allow_tree_selection = value;
    },
    
    resetStateData(state, payload){
        state.levels = payload; //null
        state.selected_level = null;
        state.current_level_id = null;
        state.level_users = null;
        state.menu_users = null;
        state.org_chart_levels = null;
        state.dropDownUsers = [];
        state.selected_user_id = null;
        state.selected_user_job_key = null;
        state.allow_tree_selection = false;
        state.levels_tree_state = null;
    },

    setRepositionValue(state, payload){
        state.reposition = payload;
    }
}
