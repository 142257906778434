/**
 * @Author: Calvin Green <calvin>
 * @Date:   2021-12-03T10:24:59+02:00
 * @Email:  calvin@diversitude.com
 * @Last modified by:   calvin
 * @Last modified time: 2022-02-24T20:41:39+02:00
 * @Copyright: Diversitude 2021
 */

import { mapState, mapGetters, mapActions } from 'vuex';

export const activityRatingMethods = mapActions('activityRating', ['saveActivityTracking']);

export const webixTableState = {
  ...mapState('webixTable', {
    last_id: (state) => state.last_id,
    has_level_tree_loaded: (state) => state.has_level_tree_loaded,
    clipboard: (state) => state.clipboard,
    batch_clipboard: (state) => state.batch_clipboard,
    duplicate_clipboard: (state) => state.duplicate_clipboard,
    is_editing_table: (state) => state.is_editing_table,
    isCtrlPressed: (state) => state.isCtrlPressed,
    treetable_states: (state) => state.treetable_states,
    dataset_table_states: (state) => state.dataset_table_states,
    table_states: (state) => state.table_states,
    table_states_array: (state) => state.table_states_array,
    active_card_ids: (state) => state.active_card_ids,
    expanded_card_ids: (state) => state.expanded_card_ids,
  }),
}
export const webixTable = mapActions('webixTable',
  [
    'setLastEditorID', 'setLevelTreeLoadedState',
    'insertIntoClipboard', 'insertIntoBatchClipboard', 'insertIntoDuplicationClipboard',
    'setEditingState',
    'setCtrlPressedState',
    'saveTreetableState', 'saveDatasetTableState',
    'updateActiveCardID', 
    'updateCardExpandedState',
  ])

export const authComputed = {
  ...mapState('auth', {
    currentUser: (state) => state.currentUser,
  }),
  ...mapGetters('auth', ['loggedIn']),
};

export const layoutComputed = {
  ...mapState('layout', {
    layoutType: (state) => state.layoutType,
    leftSidebarType: (state) => state.leftSidebarType,
    layoutWidth: (state) => state.layoutWidth,
    topbar: (state) => state.topbar,
    loader: (state) => state.loader,
    users_tree_scroll_state: (state) => state.users_tree_scroll_state,
    debug_memory_leak: (state) => state.debug_memory_leak,
  }),
};

export const authMethods = mapActions('auth', ['clearCurrentUser', 'logIn', 'logOut', 'validate', 'refresh']);

export const layoutMethods = mapActions('layout', [
  'setMemLeakBool',
  'changeLayoutType',
  'changeLayoutWidth',
  'changeLeftSidebarType',
  'changeTopbar',
  'changeLoaderValue',
  'setUserTreeScrollState',
]);

export const authFackMethods = mapActions('authfack', ['login', 'registeruser', 'logout']);

export const notificationMethods = mapActions('notification', ['success', 'error', 'clear']);

export const todoComputed = {
  ...mapState('todo', {
    todos: (state) => state.todos,
  }),
};
export const todoMethods = mapActions('todo', ['fetchTodos']);

export const settingsComputed = {
  ...mapState('userSettings', {
    grouped: (state) => state.grouped,
    is_left_menu_collapsed: (state) => state.is_left_menu_collapsed,
  }),
};
export const settingsMethods = mapActions('userSettings', ['setGrouping', 'setLeftMenuState']);

export const adminMethods = mapActions('admin', [
  'loadCoreData',
  'clearStore',
  'setCoreLoaded',
  'loadReminderEmailTemplates',
  'saveUserLevels',
  'getUserInbox',
  'setInboxItemStatus',
  'showLoginOverlay',
  'storeFailedRequest',
  'clearFailedRequest',
  'retryFailedRequests',
  'loadChangelog',
  'loadNotifications',
  'markNotificationAsRead',
]);
export const adminComputed = {
  ...mapState('admin', {
    core: (state) => state.core,
    core_loaded: (state) => state.core_loaded,
    is_core_loading: (state) => state.is_core_loading,
    reminder_email_templates: (state) => state.reminder_email_templates,
    user_inbox: (state) => state.user_inbox,
    accepted_user_inbox: (state) => state.accepted_user_inbox,
    is_loading_inbox: (state) => state.is_loading_inbox,
    show_overlay_modal: (state) => state.show_overlay_modal,
    failed_api_requests: (state) => state.failed_api_requests,
    changelog_data: (state) => state.changelog_data,
    read_notifications: (state) => state.read_notifications,
    new_notifications: (state) => state.new_notifications,
  }),
  ...mapGetters('admin', { allowedPermissions: 'allowedPermissions' }),
  ...mapGetters('admin', { allowedLevels: 'allowedLevels' }),
  ...mapGetters('admin', { assignedRoles: 'assignedRoles' }),
  ...mapGetters('admin', { userLinkedLevel: 'userLinkedLevel' }),
};

export const levelMethods = mapActions('levels', [
  'loadLevels',
  'updateSelectedLevel',
  'selectLevel',
  'clearAll',
  'loadLevelUsers',
  'loadMenuUsers',
  'saveLevels',
  'setSelectedUser',
  'loadAllUsers',
  'setEmployeeArchiveState',
  'setLevelAccessSelectionFlag',
  'setLevelAssignFlag',
  'sendDuplicationRequest',
  'saveLevelsTreeState',
  'setRepositionValue',
  'loadLevelTeams',
  'selectLevelTeam',
  'setMessageIconForUser',
  'setUserOnlineState',
]);
export const levelComputed = {
  ...mapState('levels', {
    levels: (state) => state.levels,
    menu_users: (state) => state.menu_users,
    is_loading_menu_users: (state) => state.is_loading_menu_users,
    level_users: (state) => state.level_users,
    selected_level: (state) => state.selected_level,
    current_level_id: (state) => state.current_level_id,
    org_chart_levels: (state) => state.org_chart_levels,
    dropDownUsers: (state) => state.dropDownUsers,
    selected_user_id: (state) => state.selected_user_id,
    selected_user_job_key: (state) => state.selected_user_job_key,
    selected_user_object: (state) => state.selected_user_object,
    all_users: (state) => state.all_users,
    company_id: (state) => state.company_id,
    allow_tree_selection: (state) => state.allow_tree_selection,
    assign_user_levels_mode: (state) => state.assign_user_levels_mode,
    levels_tree_state: (state) => state.levels_tree_state,
    reposition: (state) => state.reposition,

    level_teams: (state) => state.level_teams,
    selected_team_id: (state) => state.selected_team_id,
  }),
  ...mapGetters('levels', { findLevelUserId: 'findLevelUserId' }),
  ...mapGetters('levels', { getHierarchy: 'getHierarchy' }),
  ...mapGetters('levels', { all_system_users: 'all_system_users' }),
};
// export const levelGettersComputed = {
//   ...mapGetters('levels', {findLevelUserId: 'findLevelUserId'})
// }

export const activityMethods = mapActions('activity', [
  'setOwnerDropdownAction',
  'resetData',
  'clearAll',
  'deleteObjective',
  'deleteKeyResult',
  'linkableObjectiveAPI',
  'resetSelectedObjective',
  'saveObjectiveRisk',
  'deleteObjectiveRisk',
  'saveObjectiveAction',
  'saveGuidelineAPI',
  'deleteObjectiveTask',
  'saveObjectiveActionOwner',
  'getKeyResultUnitAPI',
  'createUnitType',
  'saveUnitTypes',
  'deleteUnitTypes',
  'loadObjectiveDetailAPI',
  'loadActivityData',
  'loadUserActivityData',
  'saveVision',
  'saveMission',
  'saveMissionCommentAPI',
  'saveMissionGuidelineAPI',
  'editCommentAPI',
  'deleteCommentAPI',
  'saveVisionCommentAPI',
  'saveVisionGuidelineAPI',
  'saveMilestone',
  'deleteMilestone',
  'saveObjectiveAPI',
  'saveObjectiveGuidelineAPI',
  'saveObjectivePlan',
  'saveKeyResult',
  'saveKeyResultTracking',
  'saveKpi',
  'saveKpiTracking',
  'deleteKpi',
  'linkKpi',
  'saveValidation',
  'traverseKeyResult',
  'traverseKPI',
  'traverseMetric',
  'saveActivityInboxStatus',
  'saveMetric',
  'saveMetricTracking',
  'deleteMetric',
  'linkMetric',
  'updateObjectiveProgress',
  'saveActivityInbox',
  'setObjectiveDetailAPI',
  'setSelectedActivity',
  'setSelectedPersonalActivity',
  'loadObjectiveActivities',
  'mutateGraphData',
  'setSelectedMilestone',
  'setSelectedObjective',
  'setSelectedObjectives',
  'setSelectedKeyResult',
  'updateObjectiveRowOrder',
  'updateRowOrder',
  'updateItemOrder',
  'loadAvailableKeyResultDataset',
  'loadAvailableKPIDataset',
  'loadAvailableTargetDataset',
  'loadAvailableMetricDataset',
  'saveSharedTargetGraph',
  'saveMultilineTargetGraph',
  'saveSharedKeyResultGraph',
  'saveSharedKpiGraph',
  'saveSharedMetricGraph',
  'saveMultilineKeyResultGraph',
  'saveMultilineKPIGraph',
  'saveMultilineMetricGraph',
  'setSelectedKPI',
  'setSelectedMetric',
  'filterObjectivesByQuarter',
  'loadCompanyMilestones',
  'clearCompanyMilestones',
  'setPinnedMilestoneId',
  'saveDefaultMilestone',
  'forceDeleteObjective',
  'setShowGantt',
  'showGantt',
  'setShowKanban',
  'showKanban',
  'setStartDate',
  'setEndDate',
  'setObjectiveQuarter',
  'clearData',
]);
export const activityComputed = {
  ...mapState('activity', {
    lock_level_selection: (state) => state.lock_level_selection,
    lock_objectives_selection: (state) => state.lock_objectives_selection,
    activity_data: (state) => state.activity_data,
    selected_milestone: (state) => state.selected_milestone,
    selected_objective: (state) => state.selected_objective,
    selected_objectives: (state) => state.selected_objectives,
    multiple_selected_objective_details: (state) => state.multiple_selected_objective_details,
    selected_objective_id: (state) => state.selected_objective_id,
    selected_objective_details: (state) => state.selected_objective_details,
    selected_objective_activities: (state) => state.selected_objective_activities,
    selected_key_result: (state) => state.selected_key_result,
    selected_key_performance_indicator: (state) => state.selected_key_performance_indicator,
    selected_metric: (state) => state.selected_metric,
    dropdown_parent_objective: (state) => state.dropdown_parent_objective,
    selected_activity: (state) => state.selected_activity,
    selected_personal_activity: (state) => state.selected_personal_activity,
    ownerDropdown: (state) => state.ownerDropdown,
    keyResultUnit: (state) => state.keyResultUnit,
    avaliable_shared_graphs: (state) => state.avaliable_shared_graphs,
    company_milestones: (state) => state.company_milestones,
    pinned_milestone_id: (state) => state.pinned_milestone_id,
    is_loading_activity_data: (state) => state.is_loading_activity_data,
    show_gantt: (state) => state.show_gantt,
    show_kanban: (state) => state.show_kanban,
    objective_viewing_quarter: (state) => state.objective_viewing_quarter,
    has_loaded_milestones: (state) => state.has_loaded_milestones,
    ...mapGetters('activity', {
      findObjectiveLevel: 'findObjectiveLevel',
      getViewingQuarter: 'getViewingQuarter',
    }),
  }),
};

export const handbookMethods = mapActions('process',
[
  'loadDocuments', 'loadCompanyDocuments', 'loadDocument', 'saveDocument',
  'saveRevision', 'saveComment', 'acceptDocumentRevision',
  'setRevisionAsActive', 'saveDocumentAssignments',
  'getDocumentAssignments', 'deleteProcessDocument',
  'publishDocument', 'saveDocumentCoAuthors', 'editDocumentHeartbeat',
  'setUnsavedChangesFlagState', 'saveLinkedDocument',
  'showLoadingSpinner', 'listDocumentMedia', 'autoSaveDocument',
  'lockDocument', 'unlockDocument', 'loadRevisionData',
  'resetHandbookState', 'handleNotificationChange',
  'deleteDocumentMedia', 'renameDocumentMedia', 
  'loadDocumentByToken', 'setDocumentsViewMode',
  'setLevelOnlyDocuments', 'setTreeFilterMode',
]);
export const handbookComputed = {
  ...mapState('process', {
    documents: (state) => state.documents,
    companyDocuments: (state) => state.companyDocuments,
    document: (state) => state.document,
    media_list: (state) => state.media_list,
    selected_document_assignments: (state) => state.selected_document_assignments,
    unsaved_changes_flag: (state) => state.unsaved_changes_flag,
    is_loading_document_data: (state) => state.is_loading_document_data,
    notificationChange: (state) => state.notificationChange,
    document_state: (state) => state.document_state,
    documentsViewMode: (state) => state.documentsViewMode,
    levelOnlyDocuments: (state) => state.levelOnlyDocuments,
    treeFilterMode: (state) => state.treeFilterMode,
    selected_revision_data: (state) => state.selected_revision_data
  }),
};

export const profileMethods = mapActions('profile',
[
  'setSelectedTeam','setLastUsedLevelID','setTeams', 'setSelectedJob', 'setSelectedJobId', 'manuallySetLastUsedLevelID',
  'saveLevelTeams','loadTeams','deleteTeams', 'saveServiceProviders', 'saveClientServiceProviders', 'deleteClientServiceProviders',
  'saveCommunicationTools', 'saveClientTools', 'saveValues', 'saveProductsAndServices',
  'saveLevelAssets', 'deleteServiceProviders', 'deleteCommunicationTools', 'deleteClientTools',
  'deleteProductsAndServices', 'deleteValues', 'deleteLevelAssets', 'setSelectedOrgLevel',
  'saveFrictions', 'deleteFrictions', 'setSelectedTeamId','setProcessMapID', 'setProcessMapProcessSteps', 'setProcessMapProcess', 'setProcessMapView', 'setProcessMapViewMode', 'loadProcessMapData', 'setProcessMapData', 'saveProcesses',
  'saveSystems', 'deleteSystems', 'loadPerformanceTargets','searchItem', 'searchKPIGraphs', 'setTargets', 'saveProcedures', 'saveProcessSteps', 'setKPIs', 'deleteProcesses', 'deleteProcessSteps', 'deleteProcedures', 'createGraph', 'setSearchTarget', 'setSearchKPI', 'hideOrgChart', 'changeProfileTabIndex'
]);
export const profileComputed = {
  ...mapState('profile', {
    selected_team_id: (state) => state.selected_team_id,
    selected_org_level: (state) => state.selected_org_level,
    last_used_level_id: (state) => state.last_used_level_id,
    teams: (state) => state.teams,
    linking_objective_plans: (state) => state.linking_objective_plans,
    selected_process_map_item_id: (state) => state.selected_process_map_item_id,
    selected_process_map_process_steps: (state) => state.selected_process_map_process_steps,
    selected_process_map_process: (state) => state.selected_process_map_process,
    process_map_view_mode: (state) => state.process_map_view_mode,
    targets: (state) => state.targets,
    kpis: (state) => state.kpis,
    processMapData: (state) => state.processMapData,
    searchItems: (state) => state.searchItems,
    searchKPI: (state) => state.searchKPI,
    hiddenOrgChart: (state) => state.hiddenOrgChart,
    profilePageIndex: (state) => state.profilePageIndex,
    selectedJobId: (state) => state.selectedJobId
  })
}


export const menuMethods = mapActions('menu', ['updateMenu', 'setLoading', 'clearAll']);
export const menuState = {
  ...mapState('menu', {
    menu: (state) => state.menu,
    menu_loading: (state) => state.menu_loading,
  }),
};

export const requestMethods = mapActions('requests', [
  'fetchRequestsAPI',
  'fetchApprovalUsersAPI',
  'fetchTypes',
  'approveReject',
  'editRequestAPI',
  'saveTypesAPI',
  'findApprovalUserAPI',
  'saveApprovalUserAPI',
  'deleteApprovalUserAPI',
  'saveCommentAPI',
  'deleteRequestAPI',
]);
export const requestComputed = {
  ...mapState('requests', {
    requests: (state) => state.requests,
    approval_requests: (state) => state.approval_requests,
    approvalUsers: (state) => state.approvalUsers,
    types: (state) => state.types,
  }),
};

//* ActivityNew Store
export const activityNewMethods = mapActions('activityNew', [
  'loadActivityData',
  'loadObjectiveData',
  'setSelectedMilestone',
  'setSelectedObjective',
  'setSelectedKeyResult',
  'saveMilestone',
  'saveObjective',
  'saveKeyResult',
  'setOwnerDropdownAction',
  'clearAll',
]);
export const activityNewState = {
  ...mapState('activityNew', {
    ownerDropdown: (state) => state.ownerDropdown,
    activity_data: (state) => state.activity_data,
    selected_milestone: (state) => state.selected_milestone,
    selected_objective: (state) => state.selected_objective,
    selected_key_result: (state) => state.selected_key_result,
    objective_data: (state) => state.objective_data,
  }),
};
//*------------------

//People store
export const peopleMethods = mapActions('people', [
  'saveInterviewComments',
  'loadPeopleData',
  'saveHiringJob',
  'saveJobDescription',
  'saveJobCandidate',
  'saveJobSkill',
  'saveCandidateSkill',
  'saveCandidateQualification',
  'saveJobDetails',
  'saveUserSkill',
  'saveEmployeeSkill',
  'deleteJobCandidate',
  'deleteJobSkill',
  'deleteCandidateQualification',
  'loadHiringData',
  'loadEmployeesData',
  'updateEmployee',
  'loadEmployeeData',
  'saveEmployee',
  'saveEmployeeLeave',
  'saveEmployeeRemuneration',
  'saveEmployeeAssets',
  'loadLevelJobsData',
  'deleteEmployeeRemuneration',
  'loadOnboardingData',
  'saveOnboardingChecks',
  'deleteOnboardingCheck',
  'uploadAvatar',
  'saveOnboardingTracking',
  'loadOffboardingData',
  'saveOffboardingChecks',
  'deleteOffboardingCheck',
  'saveOffboardingTracking',
  'updateEmployeeJob',
  'getTeamMembersPerformance',
  'setActiveJobId',
  'loadJobDetails',
  'loadCompanyJobs',
  'saveHiringPosition',
  'loadLinkableAssets',
  'linkEmployeesAssets',
  'unlinkEmployeesAssets',
  'updateAssetsLink',
  'loadOnboardingChecksForJob',
  'loadOffboardingChecksForJob',
  'resetJobTab',
  'loadSkillsForJob',
  'loadSkillCategories',
  'getTeamMemberObjectives',
  'setActiveMemberId',
  'getObjectiveRating',
  'deleteJob',
  'deleteHiringPosition',
  'updateEmployeeLevel',
  'setActiveHiringId',
  'setActiveCandidateId',
  'setActiveSkillId',
  'setSkillCategories',
  'loadSkillCategories',
  'loadUserPermissions',
  'loadSystemPermissions',
  'saveRolesAndPermissions',
  'saveEmployeeDetails',
  'saveEmployeeRating',
]);
export const peopleComputed = {
  ...mapState('people', {
    levelPeopleData: (state) => state.levelPeopleData,
    levelHiringData: (state) => state.levelHiringData,
    levelEmployeesData: (state) => state.levelEmployeesData,
    archivedLevelEmployeesData: (state) => state.archivedLevelEmployeesData,
    levelJobsData: (state) => state.levelJobsData,
    levelOnboardingData: (state) => state.levelOnboardingData,
    levelLinkableAssets: (state) => state.levelLinkableAssets,
    levelOffboardingData: (state) => state.levelOffboardingData,
    selectedJobDetails: (state) => state.selectedJobDetails,
    teamMembersPerformance: (state) => state.teamMembersPerformance,
    companyJobs: (state) => state.companyJobs,
    memberObjectives: (state) => state.memberObjectives,
    employeeDetails: (state) => state.employeeDetails,

    //* misc
    activeJobId: (state) => state.activeJobId,
    activeHiringPositionId: (state) => state.activeHiringPositionId,
    activeMemberId: (state) => state.activeMemberId,
    activeCandidateId: (state) => state.activeCandidateId,
    activeSkillId: (state) => state.activeSkillId,
    skillCategories: (state) => state.skillCategories,

    //* perms tab
    system_roles: (state) => state.system_roles,
    selected_user_permissions: (state) => state.selected_user_permissions,

    //* misc
    last_used_level_id: (state) => state.last_used_level_id,
  }),
};

//People store
export const cardHeightMethods = mapActions('cardHeight', ['setNewHeightState', 'setKpiHeight']);

export const cardHeightComputed = {
  ...mapState('cardHeight', {
    row1: (state) => state.row1,
    row2: (state) => state.row2,
  }),
};

//* MEETINGS MODULE
export const meetingMethods = mapActions('meetings', [
  'loadMeetings',
  'loadMeetingData',
  'createMeeting',
  'updateMeeting',
  'clearMeetingStore',
  'setSelectedMeetingID',
  'loadMeetingObjectives',
  'loadUserMeetingObjectives',
  'setSelectedMeetingObjectiveID',
  'loadMeetingActivities',
  'saveMeetingActivity',
  'saveMeetingNotes',
  'addMeetingReminder',
  'updateMeetingReminder',
  'deleteMeetingReminder',
  'deleteMeeting',
  'getMeetingNotes',
  'linkActionsToMeetings',
  'loadRecipientTypes',
]);

export const meetingState = {
  ...mapState('meetings', {
    meetings: (state) => state.meetings,
    meeting_data: (state) => state.meeting_data,
    selected_meeting_objective_id: (state) => state.selected_meeting_objective_id,
    selected_meeting_id: (state) => state.selected_meeting_id,
    meeting_objectives: (state) => state.meeting_objectives,
    meeting_activity_plans: (state) => state.meeting_activity_plans,
    is_loading_objectives: (state) => state.is_loading_objectives,
    is_loaidng_meetings: (state) => state.is_loaidng_meetings,
    is_loading_activities: (state) => state.is_loading_activities,
    recipient_types: (state) => state.recipient_types,
  }),
  ...mapGetters('meetings', ['selected_meeting']),
};

// ux store
export const uxToggleMethods = mapActions('ux/toggles', [
  'setGraphToggleState',
  'setGraphEditState',
  'setExpiryTimerState',
  'setOrgChartState',
  'setGraphLoadingState',
]);
// export const uxGraphMethods = mapActions('ux/graph',
// [   'setScrubberState',
// ]);
export const uxGraphMethods = mapActions('ux/graph', ['setScrubberState']);

export const uxToggleComputed = {
  ...mapState('ux/toggles', {
    show_kpi_toggle: (state) => state.show_kpi_toggle,
    show_org_chart: (state) => state.show_org_chart,
    show_expiry_timer: (state) => state.show_expiry_timer,
    is_editing_kpi_dataset: (state) => state.is_editing_kpi_dataset,
    is_editing_metric_dataset: (state) => state.is_editing_metric_dataset,
    is_editing_kr_dataset: (state) => state.is_editing_kr_dataset,
    is_reloading_graph_data: (state) => state.is_reloading_graph_data,
  }),
  ...mapState('ux', {
    //* take the entire graph object
    graph_scrubber_state: (state) => state.graph,
  }),
};

// new performance store
export const performanceMethods = mapActions('performance/objectives', [
  'buildObjectivesTree',
  'expandObjectives',
  'hideChildren',
  'setSelection',
  'selectMilestoneGoal',
  'selectGoalObjective',
  'selectPersonalObjective',
  'setSelectedItemDepth',
  'loadPerformanceScreenData',
  'saveObjectives',
  'deleteObjectives',
  'forceDeleteObjectives',
  'saveKeyResultData',
  'saveTargets',
  'saveKpis',
  'deleteKpi',
  'setShowKpis',
  'setSelectedTarget',
  'setSelectedKR',
  'setSelectedKRChild',
  'saveTargetTracking',
  'setObjectiveDetailsTabIndex',
  'deleteKR',
  'setVisionMissionCardVisibility',
  'setCurrentObjectiveDetails',
  'showStrategicPlanDetails',
  'saveKeyPerformanceTracking',
  'setIsEditingDataset',
  'setIsEditingDatasetKPI',
  'deleteTarget',
  'clearAll',

  'fetchSingleObjective',

  'setGraphRecalculationState',
  'fetchGraphDataset',
  'fetchGraphData',

  'fetchOkrData',

  'setKeyResultLoadingID',
  'setIsViewingObjectiveList',
]);

export const performanceComputed = {
  ...mapState('performance/objectives', {
    company_level_data: (state) => state.company_level_data,
    parent_objectives_list: (state) => state.parent_objectives_list,
    objectives_tree: (state) => state.objectives_tree,
    goal_objectives_tree: (state) => state.goal_objectives_tree,
    expanded_objectives: (state) => state.expanded_objectives,
    flat_objectives: (state) => state.flat_objectives,
    selected_object: (state) => state.selected_object,
    selected_item_object: (state) => state.selected_item_object,

    show_kpi_component: (state) => state.show_kpi_component,

    selected_target: (state) => state.selected_target,
    selected_kr_id: (state) => state.selected_kr_id,
    selected_kr_child_id: (state) => state.selected_kr_id,

    // performance 2.0
    is_editing_kpi_graph_dataset: (state) => state.is_editing_kpi_graph_dataset,
    is_editing_graph_dataset: (state) => state.is_editing_graph_dataset,
    milestone_goals: (state) => state.milestone_goals,
    goal_objectives: (state) => state.goal_objectives,
    personal_objectives: (state) => state.personal_objectives,
    selected_milestone_goal: (state) => state.selected_milestone_goal,
    selected_goal_objective: (state) => state.selected_goal_objective,
    selected_personal_objective: (state) => state.selected_personal_objective,
    selected_item_depth: (state) => state.selected_item_depth,
    current_objective_details: (state) => state.current_objective_details,
    objective_details_data: (state) => state.objective_details_data,
    level_targets: (state) => state.level_targets,
    level_kpis: (state) => state.level_kpis,

    level_plans: (state) => state.level_plans,

    is_loading_graph_data: (state) => state.is_loading_graph_data,
    is_loading_milestone_objectives: (state) => state.is_loading_milestone_objectives,
    is_loading_kpis: (state) => state.is_loading_kpis,

    show_vision_mission_cards: (state) => state.show_vision_mission_cards,

    hide_strategic_plan_details: (state) => state.hide_strategic_plan_details,

    objective_details_selected_tab_index: (state) => state.objective_details_selected_tab_index,
    kanban_start_date: (state) => state.kanban_start_date,
    kanban_end_date: (state) => state.kanban_end_date,
    locked_target_ids: (state) => state.locked_target_ids,
    locked_kpi_ids: (state) => state.locked_kpi_ids,

    is_viewing_okr_list: (state) => state.is_viewing_okr_list,
    all_okrs_list: (state) => state.all_okrs_list,
    last_loaded_okr_id: (state) => state.last_loaded_okr_id,
    key_result_loading_id: (state) => state.key_result_loading_id,
  }),
  ...mapGetters('performance/objectives', [
    'selectedMilestoneGoal',
    'selectedGoalObjective',
    'selectedLevelObjective',
    'getSelectedTarget',
    'viewableRangeDate',
    'getSelectedKR',
  ]),
};
//* GANT COMPONENT *//
export const ganttMethods = mapActions('gantt', [
  'loadGanttData',
  'showGanttModal',
  'selectGanttObjectiveRow',
  'selectGanttHeight',
  'setColumnConfig',
  'showColumnConfig',
  'setLoadingGanttAndKanban',
  'loadGanttKanbanData',
  'moveKanbanListItem',
  'clearPinnedGanttItems',
  'resetGanttKanban',
  'setPinnedGanttItems',
  'savedKeyAction',
  'hideGantt',
  'fullscreenKanban',
  'hideProjects',
  'setKanbanLoadingState',
  'getClientProjects',
  'saveClientProject',
  'deleteClientProject',
  'linkProjectKA',
  'unLinkProjectKA',
  'setProjectID',
  'selectProjectID',
  'refreshKanban', 'saveResponsibleProjectUsers',
]);

export const ganttComputed = {
  ...mapState('gantt', {
    is_loading_kanban_data: (state) => state.is_loading_kanban_data,
    gantt_tasks: (state) => state.gantt_tasks,
    pinned_gantt_tasks: (state) => state.pinned_gantt_tasks,
    kanban_data: (state) => state.kanban_data,
    loaded_kanban_config: (state) => state.loaded_kanban_config,
    gantt_modal_data: (state) => state.gantt_modal_data,
    gantt_selected_item_type: (state) => state.gantt_selected_item_type,
    gantt_selected_objective_id: (state) => state.gantt_selected_objective_id,
    gantt_column_config: (state) => state.gantt_column_config,
    gantt_kanban_loading: (state) => state.gantt_kanban_loading,
    gantt_height: (state) => state.gantt_height,
    gantt_pinned_id: (state) => state.gantt_pinned_id,
    saved_key_action: (state) => state.saved_key_action,
    hide_gantt: (state) => state.hide_gantt,
    fullscreen_kanban: (state) => state.fullscreen_kanban,
    projects: (state) => state.projects,
    selected_project_id: (state) => state.selected_project_id,
    hide_projects: (state) => state.hide_projects,
    refresh_kanban: (state) => state.refresh_kanban,
  }),
};

/**
 * ==============
 * CHAT STORE
 * ===============
 */
export const chatComputed = {
  ...mapState('chat', {
    is_loading_chat: (state) => state.chat_loading,
    is_loading_chat_messages: (state) => state.messages_loading,
    is_visible_emoji_picker: (state) => state.is_visible_emoji_picker,
    is_visible_context_menu: (state) => state.is_visible_context_menu,
    selected_message_context: (state) => state.selected_message_context,
    is_loading: (state) => state.loading || state.messages_loading,
    current_chat_room: (state) => state.current_chat_room,
    chat_room_users: (state) => state.chat_room_users,
    chat_room_particpants: (state) => state.chat_room_particpants,
    previous_chat_room: (state) => state.previous_chat_room,
    user_chat_rooms: (state) => state.chat_rooms,
    users_last_seen: (state) => state.users_last_seen,
    chat_room_messages: (state) => state.chat_room_messages,
    is_chat_visible: (state) => state.is_chat_visible,
  }),
  ...mapGetters('chat', {
    getCurrentChatRoom: 'getCurrentChatRoom',
    getChatRoomById: 'getChatRoomById',
    getChatRoomUser: 'getChatRoomUser',
    getChatRoomParticipant: 'getChatRoomParticipant',
    findChatMessages: 'findChatMessages',
    getAllUnreadMessages: 'getAllUnreadMessages',
  }),
};

export const chatMethods = mapActions('chat', [
  'init',
  'newChat',
  'toggleEmojiPickerVisible',
  'toggleContextMenuVisible',
  'toggleChatVisibility',
  'handleContextMenuDelete',
  'newChatGroup',
  'sendNewMessage',
  'readRoom',
  'onChatRoomRead',
  'onChatMessageSent',
  'onChatMessageRead',
  'onChatMessageReaction',
  'onHere',
  'onLeaving',
  'readMessage',
  'reactToMessage',
  'registerOnline',
  'getUsersLastSeen',
  'getUserChatRooms',
  'getChatRoomMessages',
  'getChatRoomMessage',
  'updateUserChatRooms',
  'updateCurrentChatRoomMessages',
]);

//* GANT COMPONENT *//
export const guidelineMethods = mapActions('guidelines',
[ 'setSelectedGuidelineID', 'saveGuidelineBody' ]);

export const guidelineComputed = {
  ...mapState('guidelines', {
    guideline_array: (state) => state.guideline_array,
    selected_guideline_id: (state) => state.selected_guideline_id,
    is_loading_guidelines: (state) => state.is_loading_guidelines,
  }),
  ...mapGetters('guidelines',[
    'getSelectedGuideline'
  ])
}
