const getDefaultState = () => {
    return {
        core: null,
        core_loaded: false,
        is_core_loading: false,
        reminder_email_templates: [],
        is_loading_inbox: false,
        user_inbox: [],
        accepted_user_inbox:[],
        show_overlay_modal: false,
        failed_api_requests: [],
        changelog_data: null,

        new_notifications:[],
        read_notifications:[]
    }
}

export const state = getDefaultState();

export const getters = {
    allowedPermissions: (state) => () => {
        if(state.core == null){
            return [];
        }
        else{
            return state.core.permissions;
        }
    },
    allowedLevels: (state) => () => {
        if(state.core == null){
            return [];
        }
        else{
            return state.core.allowed_levels;
        }
    },
    assignedRoles: (state) => () => {
        if(state.core == null){
            return [];
        }
        else{
            return state.core.role;
        }
    },
    userLinkedLevel: (state) => () => {
        let assigned_level = -1;
        if(state.core && state.core.user_levels[0] != undefined){
            assigned_level = state.core.user_levels[0].id;
        }
        return assigned_level;
    },
}

export const actions = {
    // loadNotifications({ commit, state}){
    //     return new Promise((resolve)=>{
    //         window.axios.get('api/admin/changelogs')
    //         .then( response => {
    //             commit('setChangelogData', response.data);
    //             resolve();
    //         })
    //     })
    // },
    //eslint-disable-next-line
    markNotificationAsRead({}, payload){
        return new Promise((resolve, reject) => {
            window.axios.post('api/admin/read_notification', payload)
            .then(()=>{
                resolve();
            })
            .catch(()=>{
                reject();
            })
        })
    },
    //eslint-disable-next-line
    markAllNotificationsAsRead({}, payload){
        return new Promise((resolve, reject) => {
            window.axios.post('api/admin/read_all_notifications', payload)
            .then(()=>{
                resolve();
            })
            .catch(()=>{
                reject();
            })
        })
    },
    loadNotifications({commit}){
        return new Promise((resolve,reject) => {
            const unreadPromise = new Promise((resolveUnread, rejectUnread) => {
                window.axios.get(`api/admin/notifications`, { params: { show_read: '0' } })
                .then((response) => {
                    commit('setUnreadNotifications', response.data);
                    resolveUnread();
                })
                .catch((error) => {
                    console.log('Error fetching unread notifications:', error);
                    rejectUnread();
                });
            });
    
            const readPromise = new Promise((resolveRead, rejectRead) => {
                window.axios.get(`api/admin/notifications`, { params: { show_read: '1' } })
                .then((response) => {
                    commit('setReadNotifications', response.data);
                    resolveRead();
                })
                .catch((error) => {
                    console.log('Error fetching read notifications:', error);
                    rejectRead();
                });
            });
            Promise.all([unreadPromise, readPromise])
            .then(() => {
                resolve(); // Resolve the main promise
            })
            .catch(() => {
                reject(); // Reject the main promise if either call fails
            });
        })
    },
    loadChangelog({commit}){
        return new Promise((resolve)=>{
            window.axios.get('api/admin/changelogs')
            .then( response => {
                commit('setChangelogData', response.data);
                resolve();
            })
        })
    },
    async retryFailedRequests({ commit, state }) {
        // You may want to add some logic here to prevent multiple concurrent retries
        for (const requestConfig of state.failed_api_requests) {
          try {
            // Retry the failed request
            await window.axios(requestConfig);
          } catch (error) {
            // Handle the error if the retry fails again
            console.error('Failed to retry request:', error);
          }
        }
  
        // Clear the stored failed requests after successful retries
        commit('clearFailedRequests');
    },
    getUserInbox({commit}){
        return new Promise((resolve, reject)=>{
            window.axios.get('api/admin/userinbox')//{params: {type :'reminder'}}
            .then((response)=>{
                if(response.data.active){
                    let active_notifications = response.data.active.map( item => {
                        return {...item, is_archived: false}
                    })
                    let archived_notifications = response.data.archived.map( item => {
                        return {...item, is_archived: true}
                    })
                    commit('setUserInbox', [...active_notifications, ...archived_notifications]);
                }
                
                resolve();
            })
            .catch(()=>{
                reject();
            })
        })
    },
    //eslint-disable-next-line
    setInboxItemStatus({commit}, params){
        commit('setInboxLoadingState', true)
        return new Promise((resolve, reject) => {
            window.axios.post('api/admin/saveinboxstatus', params)
            .then(()=>{
                commit('setInboxLoadingState', false);
                resolve();
            })
            .catch((error) => {
                console.log('Error', error);
                commit('setInboxLoadingState', false);
                reject();
            })
        })
    },
    loadReminderEmailTemplates({commit}){
        return new Promise((resolve, reject)=>{
            window.axios.get('api/admin/emailtemplates')
            .then((response)=>{
                commit('setReminderEmailTemplates', response.data);
                resolve();
            })
            .catch(()=>{
                reject();
            })
        })
    },
    //eslint-disable-next-line
    saveUserLevels({}, params) {
        return new Promise((resolve, reject) => {
            //eslint-disable-next-line no-undef
            window.axios.post('api/admin/saveuserlevels', params)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Level assign error', error);
                reject();
            })
        })
    },
    loadCoreData({commit}){
        //alert('loading core data')
        commit('setPreLoadState', true);
        return new Promise((resolve, reject)=>{
            window.axios.get('api/admin/core')
            .then((response)=>{
                commit('setCoreData', response.data);
                resolve();
            })
            .catch(()=>{
                reject();
            })
        })
    },
    showLoginOverlay({commit}, state = true){
        commit('setLoginOverlayFlag', state);
    },
    clearStore({commit}){
        commit('clearData', null);
    },
    setCoreLoaded({commit}, value){
        commit('setCoreLoadedState', value);
    },
}

export const mutations = {
    storeFailedRequest(state, request) {
        state.failed_api_requests.push(request);
    },
    clearFailedRequests(state) {
        state.failed_api_requests = [];
    },
    setCoreData(state, payload){
        //state.core_loaded = true;
        //TODO check if new logged in user id == old localstorage user id
        state.core = payload;
    },
    setUserInbox(state, payload){
        state.user_inbox = payload;
    },
    setInboxLoadingState(state, payload){
        state.is_loading_inbox = payload;
    },
    clearData(state, payload){
        state.core_loaded = false;
        state.core = payload;
        state.is_core_loading = false;
    },
    setCoreLoadedState(state, payload){
        state.core_loaded = payload;
        state.is_core_loading = false;
    },
    setPreLoadState(state, payload){
        state.is_core_loading = payload;
    },
    setReminderEmailTemplates(state, payload){
        state.reminder_email_templates = payload;
    },
    setLoginOverlayFlag(state, payload){
        state.show_overlay_modal = payload;
    },
    setChangelogData(state, payload){
        state.changelog_data = payload;
    },
    setReadNotifications(state, payload){
        state.read_notifications = payload;
    },
    setUnreadNotifications(state, payload){
        state.new_notifications = payload;
    },
}