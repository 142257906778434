import _ from 'lodash'
import axios from'axios'

const getDefaultState = () => {
    return {
        documents: null,
        companyDocuments: null,
        document: null,
        media_list: [],
        document_state: [],
        selected_document_assignments: null,
        unsaved_changes_flag: false,
        is_loading_document_data: false,
        notificationChange: false,
        documentsViewMode: false,
        treeFilterMode: 'all',
        levelOnlyDocuments: 0,
        selected_revision_data: null
    }
}

export const state = getDefaultState();

export const getters = {
    hasUnsavedData: state => state.unsaved_changes_flag,
};

export const actions = {
    //* LOAD ALL DOCUMENTS FOR A LEVEL
    showLoadingSpinner({commit}, value){
        commit('setLoadingState', value);
    },
    setDocumentsViewMode({commit}, value) {
        commit('setDocumentsViewModeState', value)
    },
    setTreeFilterMode({commit}, value) {
        commit('setTreeFilterModeState', value)
    },
    setLevelOnlyDocuments({commit}, value) {
        commit('setLevelOnlyDocumentsState', value)
    },
    editDocumentHeartbeat({commit}, document_id) {
        return new Promise((resolve, reject) => {
            window.axios.post('api/documents/heartbeat', {document_id:document_id})
            .then((response)=>{
                commit('setDocumentState', response.data);
                resolve();
            })
            .catch((error) => {
                console.log(error);
                reject();
            })
        })
    },
        //eslint-disable-next-line no-empty-pattern
    lockDocument({}, document_id) {
        return new Promise((resolve, reject) => {
            window.axios.post('api/documents/lock_edit', {document_id:document_id})
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log(error);
                reject();
            })
        })
    },
    //eslint-disable-next-line no-empty-pattern
    unlockDocument({}, document_id) {
        return new Promise((resolve, reject) => {
            window.axios.post('api/documents/unlock_edit', {document_id:document_id})
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log(error);
                reject();
            })
        })
    },
    loadRevisionData({commit}, revision_id) {
        return new Promise((resolve, reject)=>{
            window.axios.get('api/documents/sfdt_revision', { params : {revision_id: revision_id}})
            .then((response)=>{
                commit('setLoadedRevision', response.data);
                resolve();
            })
            .catch(()=>{
                reject();
            })
        })
    },
    handleNotificationChange({commit}, value){
        commit('setNotificationChange', value);
    },
    loadDocuments({rootState,commit}, level_id){
        let user_id = rootState.levels.selected_user_id;
        let fetch_children = rootState.process.levelOnlyDocuments;
        console.log(user_id)
        user_id = user_id != null ? user_id : -1;
        let params = {level_id: level_id, fetch_children: fetch_children}
        console.log(user_id)
        if(user_id != -1) {
            params['user_id'] = user_id
        }
        return new Promise((resolve, reject) => {
            axios.get(`api/documents/leveldocs`, {params})
            .then((response) => {
                //* backend returns same document but for different levels (eg operations + it's attendees and HR + it's )
                //* so we need to find all unique ids and combine all the attendee data into one object
                let unique_id_array = _.uniqBy(response.data, 'id').map( item => item.id)
                let docs = [];
                unique_id_array.forEach( id => {
                    //* loop through unique ids and combine all the linked level id's
                    //* AND merge all attendee arrays for the unique document
                    let doc = _.find(response.data, {id:id});
                    doc['level_ids'] = [];
                    if(doc.assignments == null){
                        //* prevent merge
                        doc.assignments = [];
                    }
                    let same_ids = _.filter(response.data, {id: id});
                    same_ids.forEach( same_doc => {
                        doc.level_ids.push(same_doc.pivot.level_id);
                        if(same_doc.assignments != null){
                            doc.assignments.push(...same_doc.assignments);
                        }
                    })
                    doc.assignments = _.uniqBy(doc.assignments, 'id')
                    docs.push(doc);

                })
                commit('setDocuments', docs);
                resolve();
            })
            .catch((error) => {
                console.log('Error:', error);
                reject();
            })
        })
    },

    loadCompanyDocuments({rootState,commit}, level_id){
        let user_id = rootState.levels.selected_user_id;
        let fetch_children = rootState.process.levelOnlyDocuments;
        user_id = user_id != null ? user_id : -1;
        let params = {level_id: level_id, fetch_children: fetch_children}

        if(user_id != -1) {
            params['user_id'] = user_id
        }
        return new Promise((resolve, reject) => {
            axios.get(`api/documents/leveldocs`, {params})
            .then((response) => {
                //* backend returns same document but for different levels (eg operations + it's attendees and HR + it's )
                //* so we need to find all unique ids and combine all the attendee data into one object
                let unique_id_array = _.uniqBy(response.data, 'id').map( item => item.id)
                let docs = [];
                unique_id_array.forEach( id => {
                    //* loop through unique ids and combine all the linked level id's
                    //* AND merge all attendee arrays for the unique document
                    let doc = _.find(response.data, {id:id});
                    doc['level_ids'] = [];
                    if(doc.assignments == null){
                        //* prevent merge
                        doc.assignments = [];
                    }
                    let same_ids = _.filter(response.data, {id: id});
                    same_ids.forEach( same_doc => {
                        doc.level_ids.push(same_doc.pivot.level_id);
                        if(same_doc.assignments != null){
                            doc.assignments.push(...same_doc.assignments);
                        }
                    })
                    doc.assignments = _.uniqBy(doc.assignments, 'id')
                    docs.push(doc);

                })
                commit('setCompanyDocuments', docs);
                resolve();
            })
            .catch((error) => {
                console.log('Error:', error);
                reject();
            })
        })
    },

    //* LOAD DOCUMENT DATA FOR SPECIFIED DOCUMENT BY TOKEN
    loadDocumentByToken({commit}, token){
        console.log(token)
        //let params = {document_id: document_id}
        return new Promise((resolve, reject) => {
            axios.get(`api/documents/document_by_token`, { params: { token: token }})
            .then((response) => {
                commit('setSelectedDocument', response.data);
                resolve();
            })
            .catch((error) => {
                reject(error);
            })
        })
    },

    //* LOAD DOCUMENT DATA FOR SPECIFIED DOCUMENT
    loadDocument({commit}, document_id){
        console.log(document_id)
        //let params = {document_id: document_id}
        return new Promise((resolve, reject) => {
            axios.get(`api/documents/document/`, { params: { document_id: document_id }})
            .then((response) => {
                commit('setSelectedDocument', response.data);
                resolve();
            })
            .catch((error) => {
                console.log('Document Error:', error);
                reject();
            })
        })
    },
    //eslint-disable-next-line no-empty-pattern
    publishDocument({}, revision_id){
        return new Promise((resolve, reject) => {
            window.axios.post('api/documents/publishrevision', {revison_id:revision_id})
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log(error);
                reject();
            })
        })
    },

    listDocumentMedia({ commit }, document_id) {
        return new Promise((resolve, reject) => {
            axios.get('api/documents/medialist', { params: { document_id: document_id }})
            .then((response) => {
                commit('setMediaList', response.data);
                resolve();
            })
            .catch((error) => {
                console.log('Document Error:', error);
                reject(error);
            })
        })
    },

    //eslint-disable-next-line no-empty-pattern
    deleteDocumentMedia({}, id) {
        return new Promise((resolve, reject) => {
            axios.post('api/documents/deletemedia', {media_id: id})
            .then((response)=>{
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
        })
    },

    //eslint-disable-next-line no-empty-pattern
    renameDocumentMedia({}, requestObject) {
        return new Promise((resolve, reject) => {
            axios.post('api/documents/renamemedia', requestObject)
            .then((response)=>{
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
        })
    },

    //* SAVE A NEW DOCUMENT REVISION
    //eslint-disable-next-line no-empty-pattern
    saveDocument({}, params){
        return new Promise((resolve, reject) => {
            axios.post('api/documents/savedoc', params)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Saving Document error', error);
                reject();
            })
        })
    },
    //eslint-disable-next-line no-empty-pattern
    saveDocumentCoAuthors({}, params){
        return new Promise((resolve, reject) => {
            axios.post('api/documents/savecoauthors', params)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Saving Document co-authors', error);
                reject();
            })
        })
    },
    //eslint-disable-next-line no-empty-pattern
    saveLinkedDocument({}, params){
        return new Promise((resolve, reject) => {
            axios.post('api/documents/save_linked_docs', params)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Saving Linked Document', error);
                reject();
            })
        })
    },
    //* DeteteDocument
    //eslint-disable-next-line no-empty-pattern
    deleteProcessDocument({}, id){
        return new Promise((resolve, reject) => {
            axios.post('api/documents/deletedoc', {document_id: id})
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Saving Document error', error);
                reject();
            })
        })
    },
    //eslint-disable-next-line no-empty-pattern
    saveDocumentAssignments({}, payload){
        return new Promise((resolve, reject) => {
            axios.post('api/documents/saveassignments', payload)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Saving Document error', error);
                reject();
            })
        })
    },

    getDocumentAssignments({commit}, params){
        return new Promise((resolve, reject) => {
            axios.get('api/documents/docassignments', { params : params })
            .then((response)=>{
                commit('setDocumentAssignments', response.data)
                resolve();
            })
            .catch((error) => {
                console.log('Saving Document error', error);
                reject();
            })
        })
    },
    //eslint-disable-next-line no-empty-pattern
    autoSaveDocument({}, formData){
        return new Promise((resolve, reject) => {
            axios.post('api/documents/save_revision', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((response)=>{
                resolve(response);
            })
            .catch((error) => {
                console.log('Saving Document error', error);
                reject();
            })
        })
    },
    //eslint-disable-next-line no-empty-pattern
    saveRevision({}, formData){
        return new Promise((resolve, reject) => {
            axios.post('api/documents/create_revision', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((response)=>{
                resolve(response);
            })
            .catch((error) => {
                console.log('Saving Revision error', error);
                reject();
            })
        })
    },

    //eslint-disable-next-line no-empty-pattern
    saveComment({}, params){
        return new Promise((resolve, reject) => {
            axios.post('api/documents/savecomment', params)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Saving comment error', error);
                reject();
            })
        })
    },
    //eslint-disable-next-line no-empty-pattern
    acceptDocumentRevision({},params){
        return new Promise((resolve, reject) => {
            axios.post('api/documents/acceptrevision', params)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Revision accept error', error);
                reject();
            })
        })
    },

    //eslint-disable-next-line no-empty-pattern
    setRevisionAsActive({},params){
        return new Promise((resolve, reject) => {
            axios.post('api/documents/setactive', params)
            .then((response)=>{
                resolve(response.data.revision_id);
            })
            .catch((error) => {
                console.log('Active revision error', error);
                reject();
            })
        })
    },
    setUnsavedChangesFlagState({commit}, value){
        commit('setUnsavedChangesFlagState', value);
    },
    resetHandbookState ({ commit }) {
        commit('resetState')
    },
}

export const mutations = {
    setLoadingState(state, payload){
        state.is_loading_document_data = payload;
    },
    setDocumentsViewModeState(state, payload){
        state.documentsViewMode = payload;
    },
    setTreeFilterModeState(state, payload){
        state.treeFilterMode = payload;
    },
    setLevelOnlyDocumentsState(state, payload){
        state.levelOnlyDocuments = payload;
    },
    setDocumentState(state, payload){
        state.document_state = payload;
    },
    setNotificationChange(state, payload){
        state.notificationChange = payload;
    },
    setDocuments(state, payload){
        state.documents = payload;
    },
    setCompanyDocuments(state, payload){
        state.companyDocuments = payload;
    },
    setSelectedDocument(state, payload){
        state.document = payload;
    },
    setDocumentAssignments(state, payload){
        state.selected_document_assignments = payload;
    },
    setUnsavedChangesFlagState(state, payload){
        state.unsaved_changes_flag = payload;
    },
    setMediaList(state, payload) {
        state.media_list = payload;
    },
    setLoadedRevision(state, payload) {
        state.selected_revision_data = payload
    },
    resetState (state) {
        // Merge rather than replace so we don't lose observers
        Object.assign(state, getDefaultState())
    }
}