// helper methods for using the table component
import store from '@/state/store'
import Swal from "sweetalert2";
export default {
    methods:{
        isRequiredValidation(value, is_empty){
            if(is_empty){
                return true;
            }
            else{
                if(value === '' || value == undefined){
                    return false;
                }
                else{
                    return true;
                }
            }
        },
        $allTablesUnsavedChangesCheck(){
            const regestered_table_uids = Object.keys(store.state.webixTable.table_states);
            if(!regestered_table_uids){
                return false;
            }
            let has_unsaved_change = this.$hasUnsavedChanges(regestered_table_uids);
            return has_unsaved_change;
        },
        // for the given uid, check table state for unsaved changes
        $hasUnsavedChanges(table_uid, is_array = Array.isArray(table_uid)){
            //return new Promise( resolve => {
                if(table_uid == []){
                    return false;
                }
                const webix_state = store.state.webixTable;
                if(webix_state.table_states == {}){
                    return false;
                }
                if(!is_array){
                    const has_unsaved_changes = webix_state.table_states[table_uid] && webix_state.table_states[table_uid].changes_made;
                    if(has_unsaved_changes){
                        this._fireDiscardPrompt(table_uid)
                        return true;
                    }
                }
                else if(is_array){
                    let table = webix_state.table_states_array.find( item => {
                        return item.changes_made == true && table_uid.includes(item.table_uid);
                    })
                    if(table != undefined){
                        this._fireDiscardPrompt(table.table_uid)
                        return true;
                    }
                }
                return false;
            //})
        },
        returnTableNameForUID(uid, is_company_level = false){
            if(table_names[uid] != null){
                if(is_company_level){
                    //* retern alt name for company level view
                    if(table_names[uid].company_text != undefined){
                        //* return the comapny text -> if non is defined then default to normal text
                        return table_names[uid].company_text;
                    }
                    else{
                        table_names[uid].text;
                    }
                }
                return table_names[uid].text;
            }
            else{
                return 'Table name not registered';
            }
        },
        _fireDiscardPrompt(table_uid){
            Swal.fire({
                icon: 'warning',
                title: `${this.returnTableNameForUID(table_uid)}`,
                text: `Unsaved changes found, please save or discard your changes.`
            });
        }
    },
    _tableSortText(a, b){
        a = a.name.toString().length;
        b = b.name.toString().length;
        return a>b?1:(a<b?-1:0);
    }
}

//register table names associated to a table uid
const table_names = {
    objectivesTable: {
        text : 'Objective Table',
        company_text: 'Milestone Objective Table'
    },
    activityList: {
        text : 'Action Plan Table'
    },
    keyResultsTable: {
        text : 'Key Results Table'
    },
    krKpiTable: {
        text : 'Key Performance Indicators Table'
    },
    metricsTable: {
        text : 'Metrics Table'
    },
    milestonesTable: {
        text : 'Mission Milestone Table'
    },
    assetRegister: {
        text: 'Asset Register'
    },
    communicationTools: {
        text: 'Communication Tools'
    },
    frictionRegister: {
        text: 'Friction Register'
    },
    guidelines: {
        text: 'Guidelines'
    },
    policies: {
        text: 'Policies'
    },
    procedures: {
        text: 'Procedures'
    },
    processes: {
        text: 'Processes'
    },
    riskRegister: {
        text: 'Risk Register'
    },
    serviceProviders: {
        text: 'Service Providers'
    },
    systems: {
        text: 'Systems'
    },
    teamMembers: {
        text: 'Team Members'
    },
    teamsList: {
        text: 'Teams List'
    }
}