var mixin = {
    methods: {
      $dateInRange: function (date, dateRangeObj) {
        if (!dateRangeObj.dateStart || !dateRangeObj.dateEnd) return true;
        else
          return (
            new Date(this.$getGeneralDate(date)) >=
              new Date(this.$getGeneralDate(dateRangeObj.dateStart)) &&
            new Date(this.$getGeneralDate(date)) <=
              new Date(this.$getGeneralDate(dateRangeObj.dateEnd))
          );
      },
      $getGeneralDate(date) {

        const today = new Date(date);

      return `${today.getFullYear()}-${("0" + (today.getMonth() + 1)).slice(
        -2
      )}-${("0" + today.getDate()).slice(-2)}`;
        // return (
        //   new Date(date).getFullYear() +
        //   "-" +
        //   (new Date(date).getMonth() + 1) +
        //   "-" +
        //   new Date(date).getDate()
        // );
      },
      $abbrevMonth(month){
        let index = this.$monthToNumber(month)
        let months = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "June",
          "July",
          "Aug",
          "Sept",
          "Oct",
          "Nov",
          "Dec",
        ];

        return months[index]

      },
      $monthToNumber(month) {
        let months = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "Octorber",
          "November",
          "December",
        ];
        return months.findIndex(e=>e===month);
      },
    }
  }


  export default {
    install(Vue) {
        Vue.mixin(mixin)
    }
}