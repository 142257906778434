

export const state = {

};

export const getters = {

};

export const mutations = {

};

export const actions = {
    //eslint-disable-next-line


    //eslint-disable-next-line no-empty-pattern
    saveActivityTracking({}, params) { //* params = the object that is being saved
        return new Promise((resolve, reject) => {
            window.axios.post('api/performance/saveActivityRating', params)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Error', error);
                reject();
            })
        })
    },
};
